import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRequest } from '../../../requests';
import { useUserSites } from '../../../hooks/useUserSites';
import { useSignIn } from 'react-auth-kit';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { routes } from '../../../constants/routes';
import { ORGANIZATION_ID } from '../../../constants';
import { updateAmplitudeUserId } from '../../../util/amplitudeService';

export const useLogin = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { loadUserProfile, errorMessage: profileError, loading: profileLoading } = useUserSites();
  const navigate = useNavigate();

  const signIn = useSignIn();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const { setProfile, setSelectedOrganization } = useContext(OrganizationContext);

  const login = async (email: string, password: string, noRecap?: any) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      let response: any;

      if (noRecap) {
        response = await getRequest({
          url: 'index.php',
          data: {},
          params: {
            module: 'API',
            method: 'PaymentIntegration.login',
            email,
            password,
            noRecap,
            format: 'json'
          }
        });
      } else {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
        }

        const gReCaptchaToken = await executeRecaptcha('login');

        response = await getRequest({
          url: 'index.php',
          data: {},
          params: {
            module: 'API',
            method: 'PaymentIntegration.login',
            email,
            password,
            format: 'json',
            reCaptchaToken: gReCaptchaToken
          }
        });
      }

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.message || response.data.msg);
          return;
        }
        // User doesn't have 2FA enabled
        else if (response.data.token) {
          loadUserProfile({ auth_token: response.data.token, email: response.data.email }).then(
            (res) => {
              if (res) {
                // call amplitude setUserId after successful log in
                updateAmplitudeUserId(res.profile_details.user_id);
                setProfile(res);
                // setSelectedOrganization(res.organizations[0]);
                console.log('1111111111111111111')
                localStorage.setItem(ORGANIZATION_ID, res.organizations[0].account_id);
                if (res.profile_details.super_user == 1) {
                  signIn({
                    token: response.data.token,
                    expiresIn: 720,
                    authState: {
                      ...res.profile_details,
                      newUser: false,
                      hash: response.data.hash
                    },
                    tokenType: 'Bearer'
                  });
                  setLoading(false);
                } else if (
                  res.profile_details.account_holder == 1 &&
                  res.subscription_details.subscription.length === 0
                ) {
                  signIn({
                    token: response.data.token,
                    expiresIn: 720,
                    authState: {
                      ...res.profile_details,
                      newUser:
                        res.subscription_details.card.length === 0 &&
                        res.organizations.length === 1,
                      hash: response.data.hash
                    },
                    tokenType: 'Bearer'
                  });
                  navigate(routes.WEBSITES);
                  setLoading(false);
                } else {
                  signIn({
                    token: response.data.token,
                    expiresIn: 720,
                    authState: {
                      ...res.profile_details,
                      newUser: false,
                      hash: response.data.hash
                    },
                    tokenType: 'Bearer'
                  });
                  navigate(routes.DASHBOARD);
                  setLoading(false);
                }
              }
            }
          );
          setLoading(false);
        }
        // User has 2FA enabled, redirect to enter code
        else if (!response.data.token && response.data.two_factor_setup) {
          setLoading(false);
          return response.data;
        } else {
          setLoading(false);
          setErrorMessage('Error processing request');
        }
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMessage('Error connecting to server');
    }
  };

  return { login, errorMessage, loading };
};
