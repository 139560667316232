import React from 'react';

interface AutomaticInstallationProps {
  deepLink: string | null
  message: string | null
}

const AutomaticInstallation = ({deepLink, message}: AutomaticInstallationProps) => {
  return (
    <div className="lg:col-span-3 flex flex-col gap-5">
      <div className='rounded-md border border-gray-200 bg-white p-3 md:p-5 text-gray-500'>
        {deepLink && (
          <ul className="mb-5">

            If you have not installed our extension before:
            <li>1. Follow the <a className={"text-blue-900  underline decoration-solid"} target={'_blank'} rel="noreferrer" href={deepLink}>link</a> and install it</li>
            <li>2. Verify the site by clicking on the button below</li>
          </ul>
        )
        }

      </div>
    </div>
  );
};

export default AutomaticInstallation;

export const AutomaticInstallationSkeleton = () => {
  return (
    <div className="lg:col-span-3 flex flex-col gap-5 animate-pulse">
      <div className='rounded-md border border-gray-200 bg-white p-3 md:p-5 text-gray-500'>
        <div className="w-full h-8 bg-gray-300 rounded mb-2"></div>
        <div className="w-full h-8 bg-gray-300 rounded mb-2"></div>
      </div>
    </div>
  )
}