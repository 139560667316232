import { createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { IOrganization } from '../interfaces/organization';
import {useAuthHeader, useAuthUser, useSignIn} from 'react-auth-kit';
import { useUserSites } from '../hooks/useUserSites';
import { ORGANIZATION_ID } from '../constants';
import { IUserProfile } from '../interfaces/user';
import { ISubscription } from '../interfaces/subscription';
import { IUserWebsite } from '../interfaces/website';
import {useSearchParams} from "react-router-dom";

interface IOrganizationProfile {
  organizations: IOrganization[];
  profile_details: IUserProfile | null;
  subscription_details: ISubscription | null;
  // subscription_details?.subscription: IUserWebsite[];
  subscription_caps: any;
}

interface IProfileContext {
  selectedOrganization: IOrganization | any;
  setSelectedOrganization: Dispatch<SetStateAction<IOrganization | any>>;
  profile: IOrganizationProfile;
  setProfile: Dispatch<SetStateAction<IOrganizationProfile | null>>;
  loading: boolean;
  accountHolderSetupOpen: boolean;
  setAccountHolderSetupOpen: Dispatch<SetStateAction<boolean>>;
  fetchOrganizationData: (accountId?: string | number) => void;
  switchOrganization: (accountId: string | number) => void;
}

export const OrganizationContextShopify = createContext<IProfileContext>({
  selectedOrganization: null,
  setSelectedOrganization: () => {},
  profile: {
    organizations: [],
    profile_details: null,
    subscription_details: null,
    // subscription_details?.subscription: [],
    subscription_caps: null
  },
  setProfile: () => {},
  loading: true,
  accountHolderSetupOpen: false,
  setAccountHolderSetupOpen: () => {},
  fetchOrganizationData: () => {},
  switchOrganization: () => {}
});

const OrganizationContextShopifyProvider = ({ children }: { children: any }) => {
  const [selectedOrganization, setSelectedOrganization] = useState<IOrganization | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState({
    organizations: [],
    profile_details: null,
    subscription_details: null,
    // subscription_details?.subscription: [],
    subscription_caps: null
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const paramsToken = searchParams.get("token");
  const paramsDomain = searchParams.get("domain");
  const paramsHash = searchParams.get("hash");
  const signIn = useSignIn();

  const [accountHolderSetupOpen, setAccountHolderSetupOpen] = useState<boolean>(false);

  const { loadUserProfile } = useUserSites();

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');
  const auth = useAuthUser();

  const switchOrganization = async (accountId: number | string | null) => {
    setLoading(true);
    let response;

    if (accountId !== 'all') {
      response = await loadUserProfile({
        account_id: accountId
      });
    } else {
      response = await loadUserProfile({});
    }

    if (response && accountId !== 'all') {
      console.log('3333333333333333333333')
      localStorage.setItem(ORGANIZATION_ID, response?.profile_details?.account_id || (accountId as string) || auth()?.account_id);
      setProfile(response);
      setSelectedOrganization(
        response.organizations.find((item: IOrganization) => item.account_id == accountId)
      );
      setLoading(false);
    } else {
      // localStorage.setItem(ORGANIZATION_ID, (accountId as string) || auth()?.account_id);
      setProfile(response);
      setSelectedOrganization(null);
      setLoading(false);
    }
  };

  const fetchOrganizationData = (accountId?: string) => {
    setLoading(true);
    loadUserProfile({
      account_id: accountId || auth()?.account_id
    }).then((response) => {
      if (response) {
        setProfile(response);
        setSelectedOrganization(
          accountId
            ? response.organizations.find((item: IOrganization) => item.account_id == accountId)
            : null
        );
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (paramsToken && paramsDomain && paramsHash) {
      localStorage.removeItem(ORGANIZATION_ID)
      loadUserProfile({ auth_token: paramsToken }).then(
        (res) => {
          setProfile(res);
          localStorage.setItem(
            ORGANIZATION_ID,
            res?.organizations[0].account_id || auth()?.account_id
          );

          signIn({
            token: paramsToken,
            expiresIn: 720,
            authState: {
              ...res.profile_details,
              newUser: false,
              hash: paramsHash
            },
            tokenType: 'Bearer'
          });

          localStorage.setItem('shopify_auth_token', paramsToken) //shopify_auth_token
          localStorage.setItem('shopify_auth_domain', paramsDomain)

          searchParams.delete('domain')
          searchParams.delete('token')
          searchParams.delete('hash')
          setSearchParams(searchParams)
          setLoading(false);
        }
      );
    }else  if (token && localStorage && localStorage.getItem(ORGANIZATION_ID)) {
      switchOrganization(localStorage && localStorage.getItem(ORGANIZATION_ID));
    } else if (token ) {
      loadUserProfile({ account_id: localStorage && localStorage.getItem(ORGANIZATION_ID) }).then(
        (res) => {
          setProfile(res);
          localStorage.setItem(
            ORGANIZATION_ID,
            res.organizations[0].account_id || auth()?.account_id
          );
          setSelectedOrganization(null);
          setLoading(false);
        }
      );
    }
  }, []);

  const values = useMemo(() => {
    return {
      profile,
      setProfile,
      loading,
      selectedOrganization,
      setSelectedOrganization,
      fetchOrganizationData,
      switchOrganization,
      accountHolderSetupOpen,
      setAccountHolderSetupOpen
    };
  }, [profile, selectedOrganization, loading, accountHolderSetupOpen]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <OrganizationContextShopify.Provider value={values}>{children}</OrganizationContextShopify.Provider>;
};

export default OrganizationContextShopifyProvider;