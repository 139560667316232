import React, {useState} from 'react';
import OnboardingProgressBar from "./OnboardingProgressBar";
import LoadingSpinnerMini from "../../../components/global/LoadingSpinnerMini";
import {useEditWebsite} from "../../../pages/main/Websites/useEditWebsite";
import {useQuery} from "../../../util/global";
import {brands} from "../../../constants";

const ThirdPartyPageBuilders = ({
  changeStep,
  selectedPageBuilderStatus,
  setSelectedPageBuilderStatus,
  setTracking,
}: {
  changeStep: (s: number) => void;
  selectedPageBuilderStatus: string | null;
  setSelectedPageBuilderStatus: any;
  setTracking: any;
}) => {
  const [message, setMessage] = useState<string | null>(null);
  const { editWebsite, loading, errorMessage } = useEditWebsite();
  const query = useQuery();


  const handleConfirmPageBuilderStatus = async () => {
    setMessage(null);
    const response = await editWebsite({
      siteId: query && (query.get('siteId') as string),
      page_builder: selectedPageBuilderStatus?.toLowerCase(),
      url: query && (query.get('url') as string),
      tracking: 1,
      platform: brands[0].key.toLowerCase(),
    });

    if (response?.result === 'success') {
      setMessage(response.msg);
      setTracking({
        script: response.tracking.script,
        orderStatusScript: response.tracking.orderstatus,
        postPurchaseScript: response.tracking.postpurchase,
        woocommerce: response.tracking.woocommerce,
        bigcommerce: response.tracking.bigcommerce,
        conversion: response.tracking.conversion,
        platform: response.website.platform
      });
      changeStep(4);
    }
  };

  return (
    <>
      <OnboardingProgressBar step={3} />
      <div className="mb-3">
        <p className="text-3 black my-8 text-align-center">
          Do you use any additional 3rd party page builders?
        </p>

        <div>
          <p className={'text-center'}>
            <span className={'font-medium'}>Examples:</span> <br/>
            Unbounce, Replo, Zipify, Gempages, Shogun, Webflow, etc
          </p>
        </div>
        <div className={'flex justify-center mt-10'}>
          <div className={'grid grid-cols-2 gap-5 flex-1 w-2/3'}>
            {['Yes', 'No'].map((item, index) => (
              <button
                type={'button'}
                key={index}
                onClick={() => setSelectedPageBuilderStatus(item)}
                className={` drop-shadow font-medium px-5 py-2 text-base border border-gray-300 rounded ${
                  selectedPageBuilderStatus == item
                    ? 'bg-[#008160] hover:text-white hover:bg-[#008160]'
                    : 'bg-white hover:bg-[#E2FDEC]'
                } cursor-pointer`}>
                {item}
              </button>
            ))}
          </div>
        </div>

        <div className={'flex justify-center mt-5'}>
          <div className={'w-full md:w-[60%] grid gap-3'}>
            <button
              disabled={!selectedPageBuilderStatus}
              type={'button'}
              onClick={handleConfirmPageBuilderStatus}
              className={`rounded-md px-3 py-3 ${
                selectedPageBuilderStatus
                  ? 'bg-[#008160] hover:bg-emerald-600 text-white'
                  : 'bg-[#F6F6F6] text-gray-600'
              } w-full font-medium text-sm`}>
              {loading ? (
                <div className={'flex justify-center'}>
                  <LoadingSpinnerMini color={'text-white'}/>
                </div>
              ) : (
                'Next'
              )}
            </button>
            <button
              type={'button'}
              onClick={() => changeStep(2)}
              className={'px-3 py-2 bg-transparent text-[#008160] w-full font-medium text-sm'}>
              Go Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThirdPartyPageBuilders;