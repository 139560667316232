import {useContext, useEffect, useState} from 'react';
import { IPlan, IPlanInterval } from '../../../interfaces/components';
import Layout from '../../../layouts/global/Layout';
import SubscriptionCheckoutForm from '../../../feature/AccountSubscription/Checkout/SubscriptionCheckoutForm';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import { ISubscription } from '../../../interfaces/subscription';
import {
  AGENCY_BOOKING_URL,
  agencyPlanItems,
  basicPlanItems,
  businessPlanItems, platforms,
  premiumPlanItems
} from '../../../constants';
import PackageItem from '../../../feature/Plans/PlanPackageItem';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { AccountHolderSetup } from '../../../feature/AccountProfile/AccountHolderSetup';
import { plan } from '../Plans';
import Payment from '../../../feature/AccountSubscription/Payments/Payment';
import SelectPlatform from "./SelectPlatrorm";
import {IBrand} from "../../../feature/Websites/Onboarding/PlatformBrand";
import ShopifyInstallForm from "./ShopifyInstallForm";

export const plans = [
  { name: 'Starter', value: 1, monthlyPrice: 89, yearlyPrice: 37 },
  { name: 'Grow', value: 2, monthlyPrice: 129, yearlyPrice: 79 },
  { name: 'Scale', value: 3, monthlyPrice: 249, yearlyPrice: 79 },
  { name: 'Scale Plus', value: 4, monthlyPrice: 449, yearlyPrice: 79 },
  { name: 'Scale Plus 1', value: 5, monthlyPrice: 750, yearlyPrice: 79 },
  { name: 'Scale Plus 2', value: 6, monthlyPrice: 1250, yearlyPrice: 79 },
  { name: 'Scale Plus 3', value: 7, monthlyPrice: 3000, yearlyPrice: 79 }
];

export const intervals = [
  { name: 'Monthly', value: 'month' },
  { name: 'Yearly', value: 'year' }
];

export default function Pricing() {
  const [selectedPackage, setSelectedPackage] = useState<IPlan | null>(plans[1]);
  const [intervalSelected, setIntervalSelected] = useState<IPlanInterval>(intervals[0]);
  const [premiumSession, setPremiumSession] = useState<number>(0);
  const [selectedPlatform, setSelectedPlatform] = useState<IBrand | null>(null);

  const [option, setOption] = useState<number>(2);

  const { profile, loading, accountHolderSetupOpen, setAccountHolderSetupOpen } =
    useContext(OrganizationContext);
  const [addCardOpen, setAddCardOpen] = useState<boolean>(false);

  useEffect(() => {
    if (selectedPlatform && selectedPlatform.key === platforms[0].key) {
      window.location.href = `https://apps.shopify.com/${process.env.REACT_APP_SHOPIFY_APP_NAME}`
    }
  }, [selectedPlatform]);

  return (
    <Layout>
      <>
        {addCardOpen && profile.subscription_details && selectedPackage && (
          <Payment
            plan={selectedPackage}
            subscription={profile.subscription_details as ISubscription}
            interval={intervalSelected}
            closeModal={() => setAddCardOpen(false)}
          />
        )}
        {accountHolderSetupOpen && (
          <AccountHolderSetup closeModal={() => setAccountHolderSetupOpen(false)} />
        )}
        <main className={'flex flex-col justify-center'}>
          <div>
            <div className={'flex flex-col justify-center'}>
              <div
                className={
                  'flex flex-col lg:flex-row justify-center items-center lg:justify-between mb-3'
                }>
                <div className={'text-center lg:text-left'}>
                  <h1 className={'font-medium text-lg lg:text-xl'}>Purchase new subscription</h1>
                  <p className={'text-sm lg:text-sm'}>Choose a plan for your website needs</p>
                </div>
              </div>
            </div>
            <div className={'grid lg:grid-cols-3 gap-10'}>
              <div className={'col-span-1'}>
                <div className={'grid gap-3 md:grid-cols-3 lg:grid-cols-1 lg:grid-rows-3'}>
                  <div
                    onClick={() => {
                      setOption(1);
                      setSelectedPackage(plans[0]);
                    }}
                    className={`${
                      option == 1 ? 'border-[#091226]' : 'border-transparent'
                    } border lg:border-4 rounded md:rounded-md bg-[#B0F6C2] p-5 cursor-pointer`}>
                    <div className={'flex flex-row justify-between gap-2'}>
                      <div className={'flex gap-2'}>
                        <h3 className={'font-extrabold text-[1.9375rem] lg:text-[1.75rem]'}>
                          {plan[0].name}
                        </h3>
                      </div>

                      <div
                        className={'mb-5 flex flex-col lg:flex-row items-end gap-4 leading-none'}>
                        <span className={'flex items-end'}>
                          <span className={'font-extrabold text-[3.3125rem] lg:text-[2.8125rem]'}>
                            $
                          </span>
                          <span className={'font-extrabold text-[3.3125rem] lg:text-[2.8125rem]'}>
                            {intervalSelected?.value === 'month'
                              ? plan[0].monthlyPrice
                              : plan[0].yearlyPrice * 12}
                          </span>
                        </span>
                        <span className={'text-[0.8125rem] lg:text-[0.75rem]'}>
                          <span className={'inline lg:hidden'}>USD</span>/{intervalSelected?.value}
                        </span>
                      </div>
                    </div>

                    <div className={'lg:mt-2'}>
                      <div className={'px-0 w-full '}>
                        <span className={'font-black text-[0.875rem]'}>
                          Track up to $50,000 per month
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setOption(2);
                      setSelectedPackage(plans[1]);
                    }}
                    className={`${
                      option == 2 ? 'border-[#091226]' : 'border-transparent'
                    } border lg:border-4 rounded md:rounded-md relative bg-[#77DB89] p-5 cursor-pointer`}>
                    <span
                      className={
                        'w-full rounded-t absolute top-0 left-0 h-[0.625rem] bg-[#091226]'
                      }></span>
                    <div className={'flex flex-row justify-between gap-2'}>
                      <div className={'flex gap-2'}>
                        <h3 className={'font-extrabold text-[1.9375rem] lg:text-[1.75rem]'}>
                          {plan[1].name}
                        </h3>
                      </div>

                      <div
                        className={'mb-5 flex flex-col lg:flex-row items-end gap-4 leading-none'}>
                        <span className={'flex items-end'}>
                          <span className={'font-extrabold text-[3.3125rem] lg:text-[2.8125rem]'}>
                            $
                          </span>
                          <span className={'font-extrabold text-[3.3125rem] lg:text-[2.8125rem]'}>
                            {intervalSelected?.value === 'month'
                              ? plan[1].monthlyPrice
                              : plan[1].yearlyPrice * 12}
                          </span>
                        </span>
                        <span className={'text-[0.8125rem] lg:text-[0.75rem]'}>
                          <span className={'inline lg:hidden'}>USD</span>/{intervalSelected?.value}
                        </span>
                      </div>
                    </div>

                    <div className={'lg:mt-2'}>
                      <div className={'px-0 w-full '}>
                        <span className={'font-black text-[0.875rem]'}>
                          Track up to $250,000 per month
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setOption(3);
                      setSelectedPackage(plans[2]);
                    }}
                    className={`${
                      option == 3 ? 'border-[#77DB89]' : 'border-transparent'
                    } border lg:border-4 rounded md:rounded-md bg-[#091226] text-white p-5 cursor-pointer`}>
                    <div className={'flex flex-row justify-between gap-2'}>
                      <div className={'flex gap-2'}>
                        <h3 className={'font-extrabold text-[1.9375rem] lg:text-[1.75rem]'}>
                          {plan[2].name}
                        </h3>
                      </div>

                      <div
                        className={'mb-5 flex flex-col lg:flex-row items-end gap-4 leading-none'}>
                        <span className={'flex items-end'}>
                          <span className={'font-extrabold text-[3.3125rem] lg:text-[2.8125rem]'}>
                            $
                          </span>
                          <span className={'font-extrabold text-[3.3125rem] lg:text-[2.8125rem]'}>
                            {intervalSelected?.value === 'month' && premiumSession === 0
                              ? plan[2].monthlyPrice
                              : intervalSelected?.value === 'month' && premiumSession === 1
                              ? plan[3].monthlyPrice
                              : plan[2].yearlyPrice * 12}
                          </span>
                        </span>
                        <span className={'text-[0.8125rem] lg:text-[0.75rem]'}>
                          <span className={'inline lg:hidden'}>USD</span>/{intervalSelected?.value}
                        </span>
                      </div>
                    </div>

                    <div className={'lg:mt-2'}>
                      <div className={'px-0 w-full '}>
                        <span className={'font-black text-[0.875rem]'}>
                          Track up to {premiumSession == 1 ? '$1,000,000' : '$500, 000'} per month
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className={'flex flex-col bg-white border border-[#091226] rounded-md p-5'}>
                    <div className={'flex gap-2'}>
                      <h3 className={'font-extrabold text-[1.75rem]'}>Agency</h3>
                    </div>

                    <div className={'flex flex-col'}>
                      <div className={'mt-3'}>
                        <p className={'font-bold text-[0.875rem]'}>
                          Manage 5 or more websites? Talk to our team to create a plan suited for
                          you.
                        </p>
                      </div>
                      <div className={'mt-5'}>
                        <a
                          href={AGENCY_BOOKING_URL}
                          target={'_blank'}
                          title={'Book a call'}
                          className={
                            'flex justify-center items-center px-3 h-[2.8125rem] hover:text-white rounded w-full text-[0.875rem] bg-[#77DB89] hover:bg-[#091226] mb-2 text-center'
                          }
                          rel="noreferrer">
                          <span>Book a call</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'lg:col-span-2'}>
                <div className={'bg-emerald-50 p-5 rounded mb-3'}>
                  <h5 className={'font-semibold text-base text-black'}>Package includes:</h5>
                  {option == 1 && (
                    <ul className={'mt-2 grid gap-2 text-sm'}>
                      {basicPlanItems &&
                        basicPlanItems.map((item, index) => (
                          <PackageItem color={'text-[#091226]'} key={index} value={item} />
                        ))}
                    </ul>
                  )}
                  {option == 2 && (
                    <ul className={'mt-2 grid gap-2 list-disc text-sm'}>
                      {businessPlanItems &&
                        businessPlanItems.map((item, index) => (
                          <PackageItem color={'text-[#091226]'} key={index} value={item} />
                        ))}
                    </ul>
                  )}
                  {option == 3 && (
                    <ul className={'mt-2 grid gap-2 list-disc text-sm'}>
                      {premiumPlanItems &&
                        premiumPlanItems.map((item, index) => (
                          <PackageItem color={'text-[#091226]'} key={index} value={item} />
                        ))}
                    </ul>
                  )}
                  {option == 4 && (
                    <ul className={'mt-2 grid gap-2 text-sm'}>
                      {agencyPlanItems &&
                        agencyPlanItems.map((item, index) => (
                          <PackageItem color={'text-[#091226]'} key={index} value={item} />
                        ))}
                    </ul>
                  )}
                </div>
                {option == 3 && (
                  <div className={'flex'}>
                    <div className={'p-1 rounded-md bg-gray-200'}>
                      <button
                        type={'button'}
                        onClick={() => {
                          setPremiumSession(0);
                          setSelectedPackage(plans[2]);
                        }}
                        className={`px-4 py-2 ${
                          premiumSession == 0 ? 'bg-[#008160] text-white' : 'bg-transparent'
                        } text-sm rounded drop-shadow`}>
                        $500,000 /month
                      </button>
                      <button
                        type={'button'}
                        onClick={() => {
                          setPremiumSession(1);
                          setSelectedPackage(plans[3]);
                        }}
                        className={`px-4 py-2 ${
                          premiumSession == 1 ? 'bg-[#008160] text-white' : 'bg-transparent'
                        } text-sm rounded drop-shadow`}>
                        $1,000,000 /month
                      </button>
                    </div>
                  </div>
                )}
                {/*<div className={'mt-3'}>*/}
                {/*  {loading ? (*/}
                {/*    <div className={'h-[40vh] flex flex-col justify-center items-center p-5'}>*/}
                {/*      <LoadingSpinner color={'text-[#008160]'} />*/}
                {/*    </div>*/}
                {/*  ) : profile.subscription_details && option !== 4 ? (*/}
                {/*    <>*/}
                {/*      {*/}
                {/*        //   profile.profile_details?.account_holder == 0 ||*/}
                {/*        // profile.profile_details?.permission == 'read' ? (*/}
                {/*        //   <div*/}
                {/*        //     className={*/}
                {/*        //       'min-h-[30vh] flex gap-3 mb-5 p-5 bg-amber-50 flex-col justify-center items-center'*/}
                {/*        //     }>*/}
                {/*        //     <p className={'text-sm font-medium'}>*/}
                {/*        //       You do not have the permission to purchase a subscription on behalf of*/}
                {/*        //       this account*/}
                {/*        //     </p>*/}
                {/*        //     <p className={'text-sm'}>*/}
                {/*        //       Kindly switch to your account to purchase a subscription*/}
                {/*        //     </p>*/}
                {/*        //   </div>*/}
                {/*        // ) :*/}
                {/*        profile.subscription_details?.card.length == 0 ? (*/}
                {/*          <div*/}
                {/*            className={*/}
                {/*              'flex gap-3 mb-5 p-5 bg-amber-50 flex-col justify-center items-center'*/}
                {/*            }>*/}
                {/*            <div>*/}
                {/*              <img*/}
                {/*                src={'/assets/icons/credit-card.png'}*/}
                {/*                className={'w-[4vw]'}*/}
                {/*                alt={''}*/}
                {/*              />*/}
                {/*            </div>*/}
                {/*            <p className={'text-sm'}>*/}
                {/*              You currently do not have any payment card attached to your account*/}
                {/*            </p>*/}
                {/*            <button*/}
                {/*              type={'button'}*/}
                {/*              onClick={() => setAddCardOpen(true)}*/}
                {/*              className={*/}
                {/*                'rounded px-2 md:px-3 py-1 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'*/}
                {/*              }>*/}
                {/*              <span>*/}
                {/*                <i className="fi fi-rr-plus text-xs"></i>*/}
                {/*              </span>*/}
                {/*              <span className={'text-xs md:text-sm'}>Buy first subscription</span>*/}
                {/*            </button>*/}
                {/*          </div>*/}
                {/*        ) : (*/}
                {/*          <SubscriptionCheckoutForm*/}
                {/*            plan={selectedPackage as IPlan}*/}
                {/*            interval={intervalSelected as IPlanInterval}*/}
                {/*            subscription={profile.subscription_details as ISubscription}*/}
                {/*          />*/}
                {/*        )*/}
                {/*      }*/}
                {/*      <div className={'flex justify-center mt-2'}>*/}
                {/*        <img src={'/assets/stripe.png'} alt={''} className={'w-1/2 md:w-1/3'} />*/}
                {/*      </div>*/}
                {/*    </>*/}
                {/*  ) : null}*/}
                {/*</div>*/}

                {selectedPlatform === null ? (
                  <div className={'mt-3'}>
                    {loading ? (
                        <div className={'h-[40vh] flex flex-col justify-center items-center p-5'}>
                          <LoadingSpinner color={'text-[#008160]'}/>
                        </div>
                      ): (
                        <SelectPlatform selectedPlatform={selectedPlatform} setSelectedPlatform={setSelectedPlatform}/>
                      )
                    }
                  </div>
                ) : (
                  <>
                    {/*{*/}
                    {/*  selectedPlatform.key === platforms[0].key && (*/}
                    {/*    <ShopifyInstallForm plan={selectedPackage}/>*/}
                    {/*  )*/}
                    {/*}*/}

                    {
                      selectedPlatform.key === platforms[1].key && (
                        <div className={'mt-3'}>
                          {loading ? (
                            <div className={'h-[40vh] flex flex-col justify-center items-center p-5'}>
                              <LoadingSpinner color={'text-[#008160]'}/>
                            </div>
                          ) : profile.subscription_details && option !== 4 ? (
                            <>
                              {profile.profile_details?.account_holder == 0 ||
                              profile.profile_details?.permission != 'admin' ? (
                                <div
                                  className={
                                    'min-h-[30vh] flex gap-3 mb-5 p-5 bg-amber-50 flex-col justify-center items-center'
                                  }>
                                  <p className={'text-sm font-medium'}>
                                    You do not have the permission to purchase a subscription on behalf of
                                    this account
                                  </p>
                                  <p className={'text-sm'}>
                                    Kindly switch to your account to purchase a subscription
                                  </p>
                                </div>
                              ) : profile.subscription_details?.card.length == 0 ? (
                                <div
                                  className={
                                    'flex gap-3 mb-5 p-5 bg-amber-50 flex-col justify-center items-center'
                                  }>
                                  <div>
                                    <img
                                      src={'/assets/icons/credit-card.png'}
                                      className={'w-[4vw]'}
                                      alt={''}
                                    />
                                  </div>
                                  <p className={'text-sm'}>
                                    You currently do not have any payment card attached to your account
                                  </p>
                                  <button
                                    type={'button'}
                                    onClick={() => setAddCardOpen(true)}
                                    className={
                                      'rounded px-2 md:px-3 py-1 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'
                                    }>
                            <span>
                              <i className="fi fi-rr-plus text-xs"></i>
                            </span>
                                    <span className={'text-xs md:text-sm'}>Buy first subscription</span>
                                  </button>
                                </div>
                              ) : (
                                <SubscriptionCheckoutForm
                                  plan={selectedPackage as IPlan}
                                  interval={intervalSelected as IPlanInterval}
                                  subscription={profile.subscription_details as ISubscription}
                                />
                              )}
                              <div className={'flex justify-center mt-2'}>
                                <img src={'/assets/stripe.png'} alt={''} className={'w-1/2 md:w-1/3'}/>
                              </div>
                            </>
                          ) : null}
                        </div>
                      )
                    }
                  </>
                )}
              </div>
            </div>
          </div>
        </main>
          {/*)}*/}
      </>
    </Layout>
  );
}
