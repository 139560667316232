import React from 'react';
import {IBrand} from "../../../feature/Websites/Onboarding/PlatformBrand";
import {platforms} from "../../../constants";
import {IPlan} from "../../../interfaces/components";

interface SelectPlatformProps  {
  setSelectedPlatform: (value: IBrand | null) => void;
  selectedPlatform: IBrand | null;
}

const SelectPlatform = ({ selectedPlatform, setSelectedPlatform}: SelectPlatformProps) => {

  return (
    <div className={'grid grid-cols-2 md:grid-cols-2 gap-4'}>
      {platforms &&
        platforms.map((item) => (
          <button
            key={item.id}
            onClick={() => setSelectedPlatform(item)}
            className={`border rounded-lg shadow p-5 ${
              selectedPlatform?.id == item.id
                ? 'bg-[#008160] hover:bg-[#008160] text-white hover:text-white'
                : 'hover:bg-[#E2FDEC]'
            } cursor-pointer`}>
            <div className={'flex flex-col justify-center items-center gap-2'}>
              <img src={item.icon} width={40} alt={''} className={'w-[25%]'}/>

              <div>
                <p className={'font-semibold text-xs text-center'}>{item.name}</p>
                {item.id == 2 && <p className={'text-xs italic'}>(Coming soon)</p>}
              </div>
            </div>
          </button>
        ))}
    </div>
  );
};

export default SelectPlatform;