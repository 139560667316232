import { useSubscription } from '../../pages/main/AccountSubscription/useSubscription';
import { useContext, useState } from 'react';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import Error from '../../components/global/ui/alerts/Error';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Modal from '../../components/global/ui/Modal';
import { ORGANIZATION_ID } from '../../constants';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import WarningPrompt from '../../components/global/ui/alerts/WarningPrompt';
import moment from 'moment';
import { useAuthUser } from 'react-auth-kit';
import {useGetShopifyAppInfo} from "../../hooks/useGetShopifyAppInfo";

export default function CancelSubscription({
  subscriptionId,
  website,
  subscriptionID,
  closeModal
}: {
  subscriptionId: string;
  website?: any;
  subscriptionID?: string;
  closeModal: () => void;
}) {
  const { cancelSubscription, cancelShopifySubscription,  loading, errorMessage } = useSubscription();
  const [message, setMessage] = useState<string | null>(null);
  const { getShopifyAppInfo, loading: shopifyAppLoading, errorMessage: shopifyAppErrorMessage } = useGetShopifyAppInfo();

  const { fetchOrganizationData } = useContext(OrganizationContext);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Enter the website name')
  });

  const formik = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema,
    onSubmit: () => handleCancelSubscription()
  });

  const handleCancelSubscription = async () => {
    setMessage(null);
    const shopifyAppInfoResponse = await getShopifyAppInfo({url: website.website.main_url})

    if (shopifyAppInfoResponse?.setupFromShopify) {

      const response = await cancelShopifySubscription({ subscriptionId,  url: website.website.main_url});
      if (response.result == 'success') {
        setMessage(response.msg);
        // refetch organization data here
        fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);

        setTimeout(() => {
          closeModal();
          // window.location.reload();
        }, 2000);
      }
    } else {
      setMessage(null);
      const response = await cancelSubscription({ subscriptionId });
      if (response.result == 'success') {
        setMessage(response.msg);
        // refetch organization data here
        fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);

        setTimeout(() => {
          closeModal();
          // window.location.reload();
        }, 2000);
      }
  }
  }

  return (
    <Modal closeModal={closeModal} title={'Cancel Subscription'} width={'w-full md:w-1/2 lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Cancelling subscription...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <div>
              <div>
                <WarningPrompt>
                  <>
                    <p className={'font-medium'}>
                      You are opting to cancel this subscription from{' '}
                      <span className={'text-[#008160]'}>Heatmap.com</span>.
                    </p>
                    <p className={'text-xs'}>
                      Kindly note that once you proceed to <strong>cancel</strong>, you will not be
                      able to reverse it
                    </p>
                    <p className={'text-xs'}>
                      Your current subscription will run till the end of{' '}
                      <strong>
                        {website.subscription
                          ? moment(website.subscription.plan_period_end).format('ll')
                          : moment(website.plan_period_end).format('ll')}
                      </strong>
                    </p>
                  </>
                </WarningPrompt>
                <div className={'mt-5'}>
                  <p className={'text-sm'}>
                    Type <strong>{website?.website?.name || subscriptionID}</strong> below to
                    proceed
                  </p>
                  <div className={'my-1'}>
                    <input
                      type={'text'}
                      name={'name'}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      className="w-full bg-transparent outline-0 px-3 py-2 rounded border border-gray-300 mt-1 flex items-center"
                    />

                    <p className={'text-xs text-red-500'}>
                      {formik.touched.name && formik.errors.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className={'grid gap-2'}>
                <div className={'mt-2 flex flex-col-reverse lg:flex-row justify-end gap-2'}>
                  <button
                    type={'button'}
                    onClick={closeModal}
                    className={`px-4 py-2 rounded text-gray-700 bg-gray-200 hover:bg-gray-300 text-xs`}>
                    No, do not cancel
                  </button>
                  <button
                    type={'button'}
                    onClick={handleCancelSubscription}
                    disabled={formik.values.name !== (website?.website?.name || subscriptionID)}
                    className={`rounded bg-white font-medium px-4 py-2 text-xs  ${
                      formik.values.name === '' ||
                      formik.values.name !== (website?.website?.name || subscriptionID)
                        ? 'border border-red-500 text-red-500 hover:bg-transparent'
                        : 'bg-red-500 hover:bg-red-600 text-white'
                    }                      `}>
                    {loading ? 'initiating cancelling..' : 'Yes, proceed to cancel'}
                  </button>
                </div>
                {errorMessage && (
                  <Error
                    value={`${errorMessage}.\n There was an error cancelling your current plan. Please contact the support team.`}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
}
