import { Link } from 'react-router-dom';
import { useContext, useRef, useState } from 'react';
import EditWebsite from './EditWebsite';
import CancelSubscription from '../AccountSubscription/CancelSubscription';
import UpgradeSubscription from '../AccountSubscription/UpgradeSubscription';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import DeleteWebsite from './DeleteWebsite';
import { IUserWebsite } from '../../interfaces/website';
import WebsiteListCardMenuPopover from '../../components/global/ui/popovers/WebsiteListCardMenuPopover';
import ViewSnippet from './ViewSnippet';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { routes } from '../../constants/routes';
import moment from 'moment';
import FlagWebsite from './FlagWebsite';
import { IWebsiteSubscription } from '../../interfaces/subscription';

export default function WebsiteListCard({ data }: { data: IWebsiteSubscription }) {
  const [selected, setSelected] = useState<IUserWebsite | null>(null);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const auth = useAuthUser();
  const { profile } = useContext(OrganizationContext);

  console.log(auth())

  const [editOpen, setEditOpen] = useState<boolean>(false);

  const [cancelOpen, setCancelOpen] = useState<boolean>(false);
  const [upgradeOpen, setUpgradeOpen] = useState<boolean>(false);
  const [flaggingOpen, setFlaggingOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [snippetOpen, setSnippetOpen] = useState<boolean>(false);
  const [menuOptionOpen, setMenuOptionOpen] = useState<boolean>(false);

  const isSuperUser = auth()?.super_user === 1;

  const openDashboard = (event: any) => {
    /*
     * Checks if user clicks edit, upgrade or cancel buttons to avoid redirection to dashboard
     *  */
    if (data.website?.verified == 1 && !event.target.className.includes('edit')) {
      window.location.replace(
        `${process.env.REACT_APP_API_URL}/index.php?module=Login&action=auth&hash=${
          auth()?.hash
        }&key=${token}&idSite=${data.idsite}&account_id=${data.account_id}`
      );
    }
  };

  return (
    <>
      {editOpen && selected && (
        <EditWebsite website={data.website as IUserWebsite} closeModal={() => setEditOpen(false)} />
      )}

      {isSuperUser && cancelOpen && selected && (
        <CancelSubscription
          subscriptionId={data.subscription_id}
          website={data}
          closeModal={() => setCancelOpen(false)}
        />
      )}

      {deleteOpen && selected && (
        <DeleteWebsite website={selected} closeModal={() => setDeleteOpen(false)} />
      )}

      {upgradeOpen && selected && (
        <UpgradeSubscription
          currentPlan={data.plan}
          currentInterval={data.plan_interval}
          subscriptionId={data.subscription_id}
          closeModal={() => setUpgradeOpen(false)}
          accountName={selected.name}
          website={selected}
        />
      )}

      {flaggingOpen && selected && (
        <FlagWebsite website={selected} closeModal={() => setFlaggingOpen(false)} />
      )}

      {snippetOpen && selected && (
        <ViewSnippet
          website={data.website as IUserWebsite}
          closeModal={() => setSnippetOpen(false)}
        />
      )}
      <div
        className={`border rounded-md border-gray-200 ${
          data.website?.verified == 1 ? 'cursor-pointer' : 'cursor-default'
        } hover:border-gray-400 bg-white hover:shadow p-3 md:p-3`}>
        <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-8 gap-2 lg:gap-5'}>
          <div onClick={openDashboard} className={'lg:col-span-3 flex gap-2'}>
            <div>
              <img
                className={'rounded w-6 h-6 object-contain'}
                src={`https://www.google.com/s2/favicons?domain=${data.website?.main_url}&sz=128`}
                alt={''}
              />
            </div>
            <div className={'flex justify-between w-full'}>
              <div className={'w-full'}>
                <div className={'flex gap-2 items-center flex-wrap'}>
                  <h5
                    className={
                      'font-medium text-base lg:text-sm capitalize truncate block max-w-[60%]'
                    }>
                    {data.website?.name}
                  </h5>
                  {data.website?.ecommerce === 1 && (
                    <>
                      <span className={'w-1 h-1 rounded-full bg-gray-400'}></span>
                      <span className={'font-normal text-sm lg:text-sm text-gray-500'}>
                        e-commerce
                      </span>
                    </>
                  )}
                </div>
                <div className={'flex gap-1 my-1'}>
                  <span>
                    <i className="fi fi-rr-link text-xs"></i>
                  </span>
                  <p className={'text-sm md:text-sm truncate block'}>{data.website?.main_url}</p>
                </div>
                {data.website?.verified == 1 ? (
                  <div>
                    <h6 className={'text-sm'}>
                      <span>Verified: </span>
                      <span>{moment(data.website?.verified_date).format('lll')}</span>
                    </h6>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div onClick={openDashboard}>
            <div className={'flex lg:flex-col gap-2 items-start md:justify-end lg:justify-start'}>
              {/*{data.website?.grace === 1 ? (*/}
              {/*  <span*/}
              {/*    className={*/}
              {/*      'flex gap-1 items-center rounded-full px-3 py-1 font-normal border border-yellow-500 text-yellow-500 bg-amber-50'*/}
              {/*    }>*/}
              {/*    <i className={'fi fi-rr-triangle-warning text-sm'}></i>*/}
              {/*    <span className={'text-xs'}>Exceeded limit</span>*/}
              {/*  </span>*/}
              {/*) : (*/}
              <span
                className={`rounded-full px-2 py-1 leading-none text-xs font-medium border ${
                  data.status == 'active'
                    ? 'bg-emerald-100 text-[#008160] border-emerald-100'
                    : data.status == 'cancelled'
                    ? 'bg-red-100 text-red-500 border-red-100'
                    : 'bg-amber-100 text-amber-600 border-amber-100'
                } uppercase`}>
                {data.status}
              </span>
              {/*)}*/}
              <span
                className={
                  'rounded-full px-2 py-1 leading-none text-xs bg-white text-[#008160] border border-[#008160] capitalize'
                }>
                {data.plan_name}
              </span>
            </div>
          </div>
          <div onClick={openDashboard}>
            {data.website?.verified == 1 ? (
              <span className={'items-start gap-1 md:gap-1 text-[#008160] hidden md:flex'}>
                <i className="fi fi-ss-badge-check text-xl md:text-xl"></i>
                <span className={'text-sm md:text-sm hidden md:flex'}>verified</span>
              </span>
            ) : (
              <span className={'items-start gap-1 md:gap-1 text-gray-500 hidden md:flex'}>
                <i className="fi fi-rr-badge-check text-gray-400 text-xl md:text-xl"></i>
                <span className={'text-sm md:text-sm hidden md:flex'}>pending</span>
              </span>
            )}
            {data.website?.verified == 0 && (
              <p className={'text-xs md:text-xs text-gray-400 hidden md:flex'}>
                Verify to view analytics
              </p>
            )}
          </div>
          <div
            className={
              'lg:col-span-3 flex gap-2 justify-between md:justify-end lg:justify-between items-start'
            }>
            <div className={'flex gap-2'}>
              {data.website?.verified == 0 &&
                profile.profile_details?.permission !== 'read' &&
                data.status != 'cancelled' && (
                  <Link
                    to={`${routes.SETUP}?step=3&siteId=${data.idsite}&url=${data.website?.main_url}`}
                    className={
                      'rounded flex justify-center items-center text-xs px-3 py-2 bg-[#008160] hover:bg-emerald-600 text-white'
                    }>
                    <span>Complete installation</span>
                  </Link>
                )}
              {data.website?.verified == 1 && (
                <div className={'flex gap-2'}>
                  <a
                    href={`${
                      process.env.REACT_APP_API_URL
                    }/index.php?module=Login&action=auth&hash=${auth()?.hash}&key=${token}&idSite=${
                      data.idsite
                    }&account_id=${data.account_id}`}
                    className={
                      'rounded shadow text-xs px-3 py-1 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'
                    }>
                    <span className={'pt-1'}>
                      <i className="fi fi-ss-chart-histogram text-sm"></i>
                    </span>{' '}
                    <span className={'text-xs'}>View analytics</span>
                  </a>
                  {data.website.early_release && (
                    <a
                      href={`${
                        process.env.REACT_APP_EARLY_ACCESS_DASHBOARD_URL
                      }?access=v5637true1&module=Login&action=auth&hash=${
                        auth()?.hash
                      }&key=${token}&idSite=${data.idsite}&account_id=${data.account_id}`}
                      className={
                        'rounded shadow text-xs px-3 py-1 flex items-center gap-2 bg-amber-500 hover:bg-amber-600 text-white'
                      }>
                      <span className={'pt-1'}>
                        <i className="fi fi-rr-sparkles text-sm"></i>
                      </span>{' '}
                      <span className={'text-xs'}>Early Access</span>
                    </a>
                  )}
                  <button
                    type={'button'}
                    onClick={() => {
                      setSelected(data.website as IUserWebsite);
                      setSnippetOpen(true);
                    }}
                    className={
                      'edit rounded flex justify-center items-center text-xs px-3 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700'
                    }>
                    View snippet
                  </button>
                </div>
              )}
            </div>
            <div className={'relative'}>
              <button
                type={'button'}
                onClick={() => {
                  setSelected(data.website as IUserWebsite);
                  setMenuOptionOpen(true);
                }}
                className={
                  'edit rounded px-2 py-1 flex items-start gap-2 border border-transparent hover:border-gray-500 bg-gray-100 text-gray-500 text-sm'
                }>
                <span>
                  <i className="edit fi fi-rs-menu-dots-vertical text-sm"></i>
                </span>
              </button>
              {menuOptionOpen && (
                <WebsiteListCardMenuPopover
                  setUpgradeOpen={setUpgradeOpen}
                  setDeleteOpen={setDeleteOpen}
                  setEditOpen={setEditOpen}
                  setCancelOpen={setCancelOpen}
                  setSnippetOpen={setSnippetOpen}
                  setFlaggingOpen={setFlaggingOpen}
                  setSelected={setSelected}
                  data={data.website as IUserWebsite}
                  onClickOutside={() => setMenuOptionOpen(false)}
                  show={menuOptionOpen}
                  subscriptionId={data.subscription_id}
                />
              )}
            </div>
          </div>
        </div>
        <div className={'flex'}>
          {profile.profile_details?.super_user == 1 && data.website?.flagged == 1 && (
            <span
              onClick={() => {
                setSelected(data.website as IUserWebsite);
                setFlaggingOpen(true);
              }}
              className={
                'edit p-2 flex items-center gap-3 cursor-pointer rounded hover:bg-amber-50'
              }>
              <span className="edit relative flex h-3 w-3">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-amber-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-amber-500"></span>
              </span>
              <span className={'edit text-sm text-amber-500'}>Suspicious</span>
            </span>
          )}
        </div>
      </div>
    </>
  );
}
