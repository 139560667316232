import {useState} from "react";
import {useAuthHeader} from "react-auth-kit";
import {getRequest} from "../../../requests";

export const useGetDeepLink = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [infoMessage, setInfoMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const getDeepLink = async ({ siteId }: {
    siteId: string
  }) => {
    setLoading(true);
    setErrorMessage(null);
    setInfoMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'ShopifyApp.getDeepLink',
          siteId,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        if (response.data.result === 'error' && response.data.action === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        if (response.data.result === 'error' && response.data.action === 'info') {
          setInfoMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }

        setLoading(false);
        return response;
      }
    } catch (e) {
      setErrorMessage('Error getting deep link');
      setLoading(false);
    }
  };




  return {
    getDeepLink,
    loading,
    infoMessage,
    errorMessage
  };
};
