import React, {useEffect, useState} from "react";
import {IUserWebsite} from "../../../interfaces/website";
import {ISubscription} from "../../../interfaces/subscription";
import {useUserSites} from "../../../hooks/useUserSites";
import {useIntercom} from "react-use-intercom";
import {ITracking} from "../../../interfaces/global";
import {Link, useNavigate} from "react-router-dom";
import {useQuery} from "../../../util/global";
import {routes} from "../../../constants/routes";
import {isMobile} from "react-device-detect";
import LoadingSpinner from "../../../components/global/LoadingSpinner";
import TopHeader from "../../../components/global/TopHeader";
import SnippetInstallationVerification, {
  PlaceholderForMobile
} from "../../../feature/Websites/Onboarding/SnippetInstallationVerification";
import AppNavbar from "../../../components/global/AppNavbar";
import Platform from "../../../feature/Websites/Onboarding/Platform";
import PlatformType from "../../../feature/Websites/Onboarding/PlatformType";
import PlatformBrand from "../../../feature/Websites/Onboarding/PlatformBrand";
import placeholderBgImg from "../../../assets/placeholder-img.png";
import BigCommerceInstruction from "../../../feature/SnippetInstallation/BigCommerceInstruction";
import WordpressWoocommerceInstruction from "../../../feature/SnippetInstallation/WordpressWoocommerceInstruction";
import ReactPlayer from "react-player";
import {SHOPIFY_INSTALLATION_VIDEO_URL} from "../../../constants";
import InstallationHelp from "../../../feature/SnippetInstallation/InstallationHelp";
import ThirdPartyPageBuilders from "../../../feature/Websites/Onboarding/ThirdPartyPageBuilders";
import {NotificationContextProvider} from "../../../contexts/NotificationContext";

export default function ShopifySetup(){
  const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [siteId, setSiteId] = useState<number | null>(null);
  const [websiteError, setWebsiteError] = useState<string | null>(null);
  const [userWebsites, setUserWebsites] = useState<IUserWebsite[] | []>([]);
  const [userSubscription, setUserSubscription] = useState<ISubscription | null>(null);
  const [selectedPageBuilderStatus, setSelectedPageBuilderStatus] = useState<string | null>(null);
  const [platformChangeShow, setPlatformShow] = useState<boolean>(false);
  const [installationMode, setInstallationMode] = useState<string>('')

  const { loadUserProfile } = useUserSites();
  const { shutdown, boot } = useIntercom();

  // State variables to handle values for snippet verification
  const [tracking, setTracking] = useState<ITracking>({
    script: null,
    orderStatusScript: null,
    postPurchaseScript: null,
    woocommerce: null,
    bigcommerce: null,
    conversion: null,
    platform: null
  });

  const navigate = useNavigate();

  const changeStep = (s: number) => setStep(s);
  const query = useQuery();

  useEffect(() => {
    // Continue from the previous step based on the step set on the url
    if (query.get('step') && query.get('step') === '2') {
      setStep(2);
    } else if (query.get('step') && query.get('step') === '3') {
      setStep(3);
    }

    if (query.get('step') && query.get('step') === '3' && !query.get('url')) {
      setWebsiteError('No URL for verification found!');
    }

    // Fetch the user websites and subscriptions
    loadUserProfile({}).then((res) => {
      // setUserWebsites(res.website_details);
      setUserSubscription(res.subscription_details);
      setLoading(false);

      /* Redirect a user to the pricing page to buy a new Subscription
        if there is no pending Subscription or unverified website
       */
      // if (
      //   res.subscription_details.subscription.filter((item: any) => item.idsite == 0).length == 0
      //   // res.website_details.subscription.filter((item: any) => item.verified == 0).length == 0
      // ) {
      //   console.log('2222')
      //   // navigate(routes.PRICING);
      // } else if (
      //   /* Redirect a user to the pricing page to buy a new Subscription
      //   if there is no pending Subscription and website Onboarding step is deleted from url
      //  */
      //   res.subscription_details.subscription.filter((item: any) => item.idsite == 0).length == 0 &&
      //   !query.get('step')
      // ) {
      //   console.log('33333')
      //   // navigate(routes.PRICING);
      // }
    });
  }, []);

  useEffect(() => {
    if (isMobile) {
      setPlatformShow(true);
      shutdown();
    } else boot();
  }, [isMobile]);

  if (loading)
    return (
      <div className={'h-screen bg-white flex justify-center items-center'}>
        <LoadingSpinner color={'text-[#008160]'} />
      </div>
    );

  if (platformChangeShow) {
    return (
      <div>
        <TopHeader />
        <main className={'h-[80vh] flex flex-col justify-center p-5'}>
          <PlaceholderForMobile />
        </main>
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <div className={'h-screen bg-white flex justify-center items-center'}>
          <LoadingSpinner color={'text-[#008160]'} />
        </div>
      ) : websiteError ? (
        <div className={'h-screen bg-white flex flex-col justify-center items-center'}>
          {websiteError}
          <Link
            to={routes.WEBSITES}
            className={
              'rounded px-4 py-2 flex items-start gap-2 bg-[#008160] text-white font-medium mt-5'
            }>
            <span>
              <i className="fi fi-rr-arrow-left text-sm"></i>
            </span>
            <span className={'text-sm'}>Go to websites</span>
          </Link>
        </div>
      ) : (
        <main className={'min-h-screen w-full'}>
          <NotificationContextProvider>
            <AppNavbar />
          </NotificationContextProvider>
          <div className="grid lg:grid-cols-2 min-h-[90vh]">
            <section className="px-10 py-10 flex flex-col items-center">
              { step === 2 ? (
                <PlatformType changeStep={changeStep} />
              ) : step === 3 ? (
                <ThirdPartyPageBuilders
                  changeStep={changeStep}
                  setTracking={setTracking}
                  selectedPageBuilderStatus={selectedPageBuilderStatus}
                  setSelectedPageBuilderStatus={setSelectedPageBuilderStatus}
                />
              ) : step === 4 && tracking ? (
                <SnippetInstallationVerification
                  changeStep={changeStep}
                  trackingDetails={tracking}
                  selectedPageBuilderStatus={selectedPageBuilderStatus}
                  showSwitch={true}
                  isShopifySetup={true}
                  setInstallationMode={setInstallationMode}
                />
              ) : null}
            </section>
            <>
              {(step === 2 ) && (
                <section className={'bg-[#008160] hidden lg:flex'}>
                  <div
                    style={{
                      backgroundImage: `url(${placeholderBgImg})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'top'
                    }}
                    className={'w-full min-h-[95vh] object-cover hidden lg:flex'}></div>
                </section>
              )}
              {(step === 3) && (
                <section
                  className={
                    'bg-[#008160] p-10 text-white text-center hidden lg:flex justify-center min-h-[90vh]'
                  }>
                  <div className={'flex justify-center items-center flex-col'}>
                    <div className={''}>
                      {tracking.platform == 'bigcommerce' ? (
                        <BigCommerceInstruction />
                      ) : tracking.platform == 'woocommerce' || tracking.platform == 'wordpress' ? (
                        <WordpressWoocommerceInstruction />
                      ) : (
                        <div className={'flex flex-col justify-center items-center'}>
                          <h3 className={'font-medium text-xl w-4/5 text-center'}>
                            Watch this quick video how-to guide on installing your heatmap.com
                            snippets
                          </h3>
                          <div className={'my-5 w-full h-[55vh]'}>
                            <ReactPlayer
                              width={'lg:100%'}
                              height={'100%'}
                              controls={true}
                              url={SHOPIFY_INSTALLATION_VIDEO_URL}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {!(tracking.platform == 'woocommerce' || tracking.platform == 'wordpress') && (
                      <InstallationHelp />
                    )}
                  </div>
                </section>
              )}

              {
                step === 4 && installationMode === 'Manual' ?
                  (
                    <section
                    className={
                      'bg-[#008160] p-10 text-white text-center hidden lg:flex justify-center min-h-[90vh]'
                    }>
                    <div className={'flex justify-center items-center flex-col'}>
                      <div className={''}>
                        {tracking.platform == 'bigcommerce' ? (
                          <BigCommerceInstruction />
                        ) : tracking.platform == 'woocommerce' || tracking.platform == 'wordpress' ? (
                          <WordpressWoocommerceInstruction />
                        ) : (
                          <div className={'flex flex-col justify-center items-center'}>
                            <h3 className={'font-medium text-xl w-4/5 text-center'}>
                              Watch this quick video how-to guide on installing your heatmap.com
                              snippets
                            </h3>
                            <div className={'my-5 w-full h-[55vh]'}>
                              <ReactPlayer
                                width={'lg:100%'}
                                height={'100%'}
                                controls={true}
                                url={SHOPIFY_INSTALLATION_VIDEO_URL}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {!(tracking.platform == 'woocommerce' || tracking.platform == 'wordpress') && (
                        <InstallationHelp />
                      )}
                    </div>
                  </section>
                  ) : (
                  <section className={'bg-[#008160] hidden lg:flex'}>
                    <div
                      style={{
                        backgroundImage: `url(${placeholderBgImg})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'top'
                      }}
                      className={'w-full min-h-[95vh] object-cover hidden lg:flex'}></div>
                  </section>
                )
              }
            </>
          </div>
          <footer
            className={
              'bg-[#008160] text-white flex flex-col items-center lg:hidden px-10 py-5 text-center w-full'
            }>
            <img src={'/assets/logo-white.svg'} width={50} alt={''} className={'w-6'}/>
            <p className={'text-sm'}>All rights reserved 2023</p>
          </footer>
        </main>
      )}
    </>
  );
}