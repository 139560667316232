import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '../../../util/global';
import { useVerifyWebsite } from '../../../pages/main/Setup/useVerifyWebsite';
import OnboardingProgressBar from './OnboardingProgressBar';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import { isMobile } from 'react-device-detect';
import { routes } from '../../../constants/routes';
import { installSnippetMode, AMPLITUDE_EVENTS, ORGANIZATION_ID } from '../../../constants';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { useAuthUser } from 'react-auth-kit';
import InstallationHelp from '../../SnippetInstallation/InstallationHelp';
import WordpressWoocommerce from '../../SnippetInstallation/Installation/WordpressWoocommerce';
import BigCommerce from '../../SnippetInstallation/Installation/BigCommerce';
import OtherPlatforms from '../../SnippetInstallation/Installation/OtherPlatforms';
import Shopify from '../../SnippetInstallation/Installation/Shopify';
import WebflowSquarespace from '../../SnippetInstallation/Installation/WebflowSquarespace';
import ThirdPartyBuilder from '../../SnippetInstallation/Installation/ThirdPartyBuilder';
import { trackAmplitudeEvent } from '../../../util/amplitudeService';
import AutomaticInstallation, {
  AutomaticInstallationSkeleton
} from "../../SnippetInstallation/Installation/AutomaticInstallation";
import {useGetDeepLink} from "../../../pages/main/Setup/useGetDeepLink";


interface IMode {
  id: number;
  name: string
}

export default function SnippetInstallationVerification({
  changeStep,
  trackingDetails,
  selectedPageBuilderStatus,
  showSwitch = false,
  isShopifySetup = false,
  setInstallationMode
}: {
  changeStep: (s: number) => void;
  trackingDetails: any;
  selectedPageBuilderStatus: string | null;
  showSwitch?: boolean;
  isShopifySetup?: boolean;
  setInstallationMode?: (value: string) => void
}) {
  const [message, setMessage] = useState<string | null>(null);
  const [autoInstallMessage, setAutoInstallMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [platformChangeShow, setPlatformShow] = useState<boolean>(false);
  const [snippetShow, setSnippetShow] = useState<number>(1);
  const [selectedMode, setSelectedMode] = useState<IMode | null>(null);
  const [deepLink, setDeepLink] = useState<string | null>(null)

  const { fetchOrganizationData } = useContext(OrganizationContext);

  const auth = useAuthUser();

  const {
    verifySite,
    shopifyVerifySite,
    loading: verifySiteLoading,
    errorMessage: verifySiteError
  } = useVerifyWebsite();

  const {getDeepLink, loading: deepLinkLoading, errorMessage: deepLinkErrorMessage} = useGetDeepLink()

  const query = useQuery();

  const handleVerifySite = async () => {
    setErrorMessage(null);
    setSnippetShow(2);
    try {
      if (query.get('siteId') && query.get('url')) {
        const res = await verifySite({
          websiteUrl: query.get('url') as string,
          siteId: query.get('siteId') as string
        });
        if (res?.data.result === 'success') {
          // register event for card added to account for the first time
          trackAmplitudeEvent(AMPLITUDE_EVENTS.SITE_VERIFIED);
          setMessage(res?.data.msg);
          // refetch organization data
          fetchOrganizationData(
            (localStorage.getItem(ORGANIZATION_ID) as string) || auth()?.account_id
          );
        } else if (res?.data.msg === 'Error! Snippet is not inserted Correctly') {
          setErrorMessage(res?.data.msg);
        }
      } else {
        setErrorMessage('Invalid site ID');
      }
    } catch (e) {
      setErrorMessage('Snippet has not been inserted');
    }
  };

  const handleShopifyVerifySite = async () => {
    setErrorMessage(null);
    setSnippetShow(2);
    try {
      if (query.get('siteId') && query.get('url') && selectedMode) {
        const res = await shopifyVerifySite({
          websiteUrl: query.get('url') as string,
          siteId: query.get('siteId') as string,
          mode: selectedMode.name
        });
        if (res?.data.result === 'success') {
          // register event for card added to account for the first time
          trackAmplitudeEvent(AMPLITUDE_EVENTS.SITE_VERIFIED);
          setMessage(res?.data.msg);
          // refetch organization data
          fetchOrganizationData(
            (localStorage.getItem(ORGANIZATION_ID) as string) || auth()?.account_id
          );
        } else {
          setErrorMessage(res?.data.msg);
        }
      } else {
        setErrorMessage('Invalid site ID or URL');
      }
    } catch (e) {
      setErrorMessage('Snippet has not been inserted');
    }
  };

  const handleSelectMode = async (mode: IMode) => {
    setSelectedMode(mode)

    if (setInstallationMode) {
      setInstallationMode(mode.name)
    }


    if (mode.id === 1) {
      try {
        if (query.get('siteId') && mode) {
          const res = await getDeepLink({
            siteId: query.get('siteId') as string,
          });
          if (res?.data.result === 'success') {
            setDeepLink(res?.data.deepLink)
            setAutoInstallMessage(res?.data.msg);
            // refetch organization data
            fetchOrganizationData(
              (localStorage.getItem(ORGANIZATION_ID) as string) || auth()?.account_id
            );
          } else if (res?.data.msg === 'Error! The deep link was not received') {
            setErrorMessage(res?.data.msg);
          }
        } else {
          setErrorMessage('Invalid site ID');
        }
      } catch (e) {
        setErrorMessage('Error! The deep link was not received');
      }
    }
  }

  useEffect(() => {
    if (isMobile) setPlatformShow(true);
  }, [isMobile]);

  return (
    <>
      <div className="lg:w-[70%] w-full">
        <OnboardingProgressBar step={4} />
        {platformChangeShow ? (
          <PlaceholderForMobile />
        ) : (
          <>
            {verifySiteLoading ? (
              <div className="mb-3">
                <div className={'my-8 text-center'}>
                  <p className="text-3 text-black text-align-center">Verification in Progress</p>
                  <div className={'flex flex-col justify-center items-center'}>
                    <p className={'text-sm text-gray-400 w-1/2'}>
                      We’re checking to make sure the snippets were installed correctly.
                    </p>
                    <div className={'my-14'}>
                      <LoadingSpinner color={'text-[#008160]'} />
                    </div>
                  </div>
                </div>
              </div>
            ) : snippetShow == 1 ? (
              <div className="mb-3">
                <p className="text-3 text-black my-8 text-center">
                  {trackingDetails.platform == 'woocommerce' ||
                  trackingDetails.platform == 'wordpress'
                    ? 'Add the WordPress/WooCommerce Plugin'
                    : 'Add Your Snippets'}
                </p>

                {showSwitch && (
                  <div className={'grid grid-cols-2 md:grid-cols-2 gap-4 mb-5'}>
                    {installSnippetMode &&
                      installSnippetMode.map((item) => (
                      <button
                      type={'button'}
                       key={item.id}
                       onClick={() => handleSelectMode(item)}
                       className={` drop-shadow font-medium px-5 py-2 text-base border border-gray-300 rounded ${
                         selectedMode?.id == item.id
                           ? 'bg-[#008160] hover:text-white hover:bg-[#008160]'
                           : 'bg-white hover:bg-[#E2FDEC]'
                       } cursor-pointer`}>
                    {item.name}
                  </button>
                ))}
              </div>
            )}

            {selectedMode && selectedMode.id === 1 && deepLinkLoading && (
              <AutomaticInstallationSkeleton/>
            )}

            {selectedMode && selectedMode.id === 1 && deepLink && !deepLinkLoading && (
              <AutomaticInstallation message={autoInstallMessage} deepLink={deepLink}/>
            )}

            {selectedMode && selectedMode.id === 2 && (
              <div>
              <div className={'flex flex-col gap-3'}>
                      {(trackingDetails.platform == 'woocommerce' ||
                        trackingDetails.platform == 'wordpress') && (
                        <WordpressWoocommerce trackingDetails={trackingDetails}/>
                      )}
                      {(trackingDetails.platform == 'webflow' ||
                        trackingDetails.platform == 'squarespace') && (
                        <WebflowSquarespace trackingDetails={trackingDetails}/>
                      )}
                      {trackingDetails.platform == 'bigcommerce' && (
                        <BigCommerce trackingDetails={trackingDetails}/>
                      )}
                      {trackingDetails.platform == 'other' && (
                        <OtherPlatforms trackingDetails={trackingDetails}/>
                      )}
                      {trackingDetails.platform == 'shopify' && (
                        <Shopify trackingDetails={trackingDetails}/>
                      )}
                    </div>
                  </div>
                )}

              </div>
            ) : message || verifySiteError || snippetShow == 2 ? (
              <div className={'flex flex-col justify-center items-center text-center'}>
                <div className={'mt-8 text-center'}>
                  <div className={'flex flex-col justify-center items-center'}>
                    {message && selectedPageBuilderStatus == 'No' && (
                      <div className={'my-20 flex gap-2 items-center'}>
                        <span>
                          <img alt={''} src={'/assets/shield-check-filled.svg'} className={'w-8'}/>
                        </span>
                        <span className={'text-base font-medium text-[#008160]'}>
                          Verification complete
                        </span>
                      </div>
                    )}
                    {message && selectedPageBuilderStatus == 'Yes' && (
                      <ThirdPartyBuilder trackingDetails={trackingDetails}/>
                    )}
                    {verifySiteError && (
                      <div className={'my-20 flex gap-2 items-center'}>
                        <span>
                          <img src={'/assets/x-02-filled.svg'} className={'w-8'} alt={''} />
                        </span>
                        <span className={'text-base font-medium text-black'}>
                          {verifySiteError || errorMessage}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null}

            {!verifySiteLoading && !message ? (
              <div className={'flex flex-col items-center mt-5'}>
                <div className={'w-full md:w-[60%] grid gap-3'}>
                  <button
                    type={'button'}
                    disabled={verifySiteLoading}
                    onClick={isShopifySetup ? handleShopifyVerifySite : handleVerifySite}
                    className={`rounded-md px-3 py-3 ${
                      verifySiteLoading
                        ? 'bg-[#F6F6F6] text-gray-600'
                        : ' bg-[#008160] hover:bg-emerald-600 text-white '
                    } w-full font-medium text-sm`}>
                    {message ? 'Next' : 'Verify Website'}
                  </button>
                  {!message && (
                    <button
                      type={'button'}
                      onClick={() => {
                        if (snippetShow == 1) {
                          changeStep(3);
                        }
                        setSnippetShow(1);
                      }}
                      className={
                        'px-3 py-2 bg-transparent text-[#008160] w-full font-medium text-sm'
                      }>
                      Go Back
                    </button>
                  )}
                </div>

                {(trackingDetails.platform == 'woocommerce' ||
                  trackingDetails.platform == 'wordpress') && <InstallationHelp />}

                {verifySiteError && (
                  <p className="text-sm mt-5 text-secondary">
                    <Link to={routes.DASHBOARD} className="text-secondary">
                      Do it later &#8594;
                    </Link>
                  </p>
                )}
              </div>
            ) : !verifySiteLoading ? (
              <div className={'flex justify-center'}>
                <Link
                  to={routes.WEBSITES}
                  className={
                    'text-sm px-3 py-1 border border-[#008160] hover:bg-emerald-100 text-[#008160] rounded flex items-center gap-2'
                  }>
                  <span>Continue to dashboard</span>
                  <span className={'pt-1'}>
                    <i className="fi fi-rr-arrow-right text-lg"></i>
                  </span>
                </Link>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
}

export function PlaceholderForMobile() {
  return (
    <div className="mb-3">
      <div className={'my-8 text-center'}>
        <p className="text-3 text-black text-align-center">Continue on Desktop</p>
        <div className={'flex flex-col justify-center items-center'}>
          <p className={'text-sm text-gray-700 w-full'}>
            Use a desktop computer to place the heatmap.com code snippet onto your site.
          </p>
          <div className={'mt-14 flex justify-center'}>
            <img alt={''} src={'/assets/desktop-placeholder.png'} className={'w-4/5'} />
          </div>
        </div>
      </div>
    </div>
  );
}
