import axios from 'axios';
import { IRequest } from '../interfaces/global';
import { BASE_URL } from '../util/config';

const getRequest = async ({ url, params }: IRequest) => {
  const path = `${BASE_URL}/${url}`;
  return await axios.get(path, { params });
};

const postRequest = async ({ url, data, params }: IRequest) => {
  const path = `${BASE_URL}/${url}`;
  return await axios.post(path, data, { params });
};

export { postRequest, getRequest };
