import { Link, useNavigate } from 'react-router-dom';
import RightSectionPlaceholder from '../../../layouts/authentication/RightSectionPlaceholder';
import {
  ChangeEvent,
  KeyboardEvent,
  FocusEvent,
  useMemo,
  useState,
  useEffect,
  useContext
} from 'react';
import { ORGANIZATION_ID, RE_DIGIT } from '../../../constants';
import AuthFormHeader from '../../../components/form/AuthFormHeader';
import PrimaryButton from '../../../components/global/ui/buttons/PrimaryButton';
import { useVerifyEmail } from './useVerifyEmail';
import TopHeader from '../../../components/global/TopHeader';
import Error from '../../../components/global/ui/alerts/Error';
import { useResendOTP } from './useResendOTP';
import Success from '../../../components/global/ui/alerts/Success';
import { useUserSites } from '../../../hooks/useUserSites';
import { useSignIn } from 'react-auth-kit';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { routes } from '../../../constants/routes';

export default function VerifyEmail() {
  const { verifyEmailAccount, errorMessage } = useVerifyEmail();
  const { resendOTP, loading: resendLoading, errorMessage: resendError } = useResendOTP();
  const { loadUserProfile } = useUserSites();
  const { setProfile } = useContext(OrganizationContext);

  const signIn = useSignIn();

  const navigate = useNavigate();

  const [otpSent, setOtpSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const [otp, setOtp] = useState('');

  const otpDigits = useMemo(() => {
    const valueArray = otp.split('');
    const items: Array<string> = [];

    for (let i = 0; i < 6; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [otp, otp.length]);

  const inputOnChange = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
    const target = e.target;
    let targetValue = target.value;
    const isTargetValueDigit = RE_DIGIT.test(targetValue);
    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const newValue = otp.substring(0, idx) + targetValue + otp.substring(idx + 1);
    setOtp(newValue);

    if (!isTargetValueDigit) {
      return;
    }

    const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  const inputOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (e.key !== 'Backspace' || target.value !== '') {
      return;
    }

    const previousElementSibling = target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnFocus = (e: FocusEvent<HTMLInputElement>) => {
    const { target } = e;

    target.setSelectionRange(0, target.value.length);
  };

  const handleVerifyEmail = async (value: string) => {
    setSuccessMessage(null);
    setLoading(true);
    const response = await verifyEmailAccount(value);
    if (response.result == 'error') {
      setOtp('');
      setLoading(false);
    } else if (response.token) {
      loadUserProfile({
        auth_token: response.token.token,
        email: response.token.email
      }).then((res) => {
        if (res) {
          setProfile(res);
          // setSelectedOrganization(res.organizations[0]);
          console.log('999999999999999999999999999')
          localStorage.setItem(ORGANIZATION_ID, res.organizations[0].account_id);
          if (res.profile_details.super_user == 1) {
            signIn({
              token: response.token.token,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser: false,
                hash: response.token.hash
              },
              tokenType: 'Bearer'
            });
          } else if (res.subscription_details.subscription.length === 0) {
            navigate(routes.PLANS);
            signIn({
              token: response.token.token,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser: res.subscription_details.subscription.length === 0,
                hash: response.token.hash
              },
              tokenType: 'Bearer'
            });
            setLoading(false);
          } else {
            signIn({
              token: response.token.token,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser: false,
                hash: response.token.hash
              },
              tokenType: 'Bearer'
            });
            setLoading(false);
            navigate(routes.DASHBOARD);
          }
        }
      });
    }
  };

  const handleResendOTP = async () => {
    setOtpSent(false);
    setSuccessMessage(null);
    const response = await resendOTP({
      email: localStorage.getItem('email') as string
    });
    if (response.result == 'success') {
      setOtpSent(true);
      setSuccessMessage(response.msg || response.message);
      setTimeout(() => {
        setOtpSent(false);
        setSuccessMessage(null);
      }, 5000);
    }
  };

  useEffect(() => {
    // Redirect to log in when no email verification is pending
    if (!localStorage.getItem('email')) {
      navigate(routes.LOGIN);
    }
  }, []);

  return (
    <div>
      <main className="main-container">
        <TopHeader />

        <div className="grid lg:grid-cols-2 sides-container">
          <section className="pt-[10vh] px-5 flex flex-col items-center">
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handleVerifyEmail(otp);
              }}
              className="w-full md:w-1/2 flex flex-col items-center">
              <AuthFormHeader
                title={'Check your email to verify.'}
                description={`We sent an email to ${localStorage.getItem(
                  'email'
                )} to verify your email address.`}
              />

              <div className={'grid grid-cols-6 gap-3 lg:gap-2 my-5'}>
                {otpDigits &&
                  otpDigits.map((digit, index) => (
                    <input
                      key={index}
                      className="bg-gray-100 w-full h-[5vh] lg:h-[8vh] rounded border border-[#E6E7E8] text-xl text-center"
                      type="text"
                      inputMode={'numeric'}
                      autoComplete={'one-time-code'}
                      pattern="\d{1}"
                      maxLength={6}
                      value={digit}
                      onKeyDown={inputOnKeyDown}
                      onFocus={inputOnFocus}
                      onChange={(e) => inputOnChange(e, index)}
                      required={true}
                    />
                  ))}
              </div>

              <PrimaryButton
                disabled={loading}
                title={loading ? 'verifying account...' : 'Confirm'}
                handler={() => handleVerifyEmail(otp)}
              />

              <p className="text-sm text-black mt-2 text-center">
                Can’t find the email? Check your spam folder, or
              </p>
              <div className={'my-1'}>
                <button
                  type={'button'}
                  onClick={handleResendOTP}
                  className={`${
                    otpSent ? 'hidden' : 'flex'
                  } text-[#008160] hover:underline text-base`}>
                  {otpSent ? 'OTP sent' : resendLoading ? 'Resending OTP...' : 'Resend OTP'}
                </button>
              </div>
              <p className="mt-5">
                <Link to={routes.REGISTER} className="text-[#008160] hover:underline">
                  Go back
                </Link>
              </p>
              {(errorMessage || resendError) && (
                <Error value={(errorMessage || resendError) as string} />
              )}
              {successMessage && <Success value={successMessage} />}
            </form>
          </section>
          <RightSectionPlaceholder />
        </div>
      </main>
    </div>
  );
}
