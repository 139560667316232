import {useState} from "react";
import {useAuthHeader} from "react-auth-kit";
import {getRequest} from "../requests";

export const useGetShopifyAppInfo = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const getShopifyAppInfo = async ({ url }: {
    url: string;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'ShopifyApp.isSetupFromShopifyApp',
          url,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error get shopify app info');
      setLoading(false);
    }
  };

  return { getShopifyAppInfo, loading, errorMessage };
}