import PrimaryButton from '../../components/global/ui/buttons/PrimaryButton';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { useContext, useState } from 'react';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import Error from '../../components/global/ui/alerts/Error';
import Modal from '../../components/global/ui/Modal';
import { useTicketIssues } from '../../pages/main/SupportTickets/useTicketIssues';
import TicketDeptsPopover from '../../components/global/ui/popovers/TicketDeptsPopover';
import { ITicketDepartment } from '../../interfaces/support-ticket';
import { OrganizationContext } from '../../contexts/OrganizationContext';

export default function CreateTicketIssue({
  closeModal,
  fetchTickets
}: {
  closeModal: () => void;
  fetchTickets: () => void;
}) {
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .max(255, 'Title cannot exceed 255 characters')
      .required('Title is required'),
    department: Yup.string(),
    message: Yup.string()
      .max(2000, 'Message cannot exceed 2000 characters')
      .required('Message is required')
  });

  const ticketDepartments: ITicketDepartment[] = [
    {
      name: 'Support',
      description: 'General issues and issues related to verification, tracking, etc',
      icon: <i className="fi fi-rr-headset text-xl"></i>,
      id: 'support'
    },
    {
      name: 'Sales & Billing',
      description: 'Issues with payments, subscriptions, invoices, etc',
      icon: <i className="fi fi-rr-credit-card text-xl"></i>,
      id: 'billing'
    },
    {
      name: 'Abuse & Security',
      description: 'Issues with account security, password reset, etc',
      icon: <i className="fi fi-rr-fingerprint text-xl"></i>,
      id: 'abuse'
    }
  ];

  const { createTicketIssue, ticketsLoading: loading, errorMessage } = useTicketIssues();

  const [message, setMessage] = useState<string | null>(null);
  const [ticketDeptShow, setTicketDeptShow] = useState<boolean>(false);

  const [selectedDept, setSelectedDept] = useState<ITicketDepartment | null>(null);
  const { profile } = useContext(OrganizationContext);

  const hideTicketDepts = () => setTicketDeptShow(false);

  const formik = useFormik({
    initialValues: {
      title: '',
      department: ticketDepartments[0].id as string,
      message: '',
      website: undefined
    },
    validationSchema,
    onSubmit: async (values) => handleCreateIssue(values)
  });

  const handleCreateIssue = async (values: FormikValues) => {
    setMessage(null);

    const response = await createTicketIssue({
      title: values.title,
      department: selectedDept?.id as string,
      message: values.message,
      siteId: values.website
    });

    if (response?.result === 'success') {
      setMessage(response.msg);
      fetchTickets();
      setTimeout(() => {
        closeModal();
      }, 3000);
    }
  };

  return (
    <Modal
      closeModal={closeModal}
      title={'Submit ticket'}
      width={message ? 'w-1/3' : 'w-full md:w-4/5 lg:w-1/2'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Submitting a ticket...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                formik.handleSubmit();
              }}>
              <div className={'grid gap-2'}>
                <div>
                  <input
                    type={'text'}
                    name={'title'}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    placeholder={'Title'}
                    autoFocus={true}
                    className="w-full bg-gray-50 border border-gray-300 rounded outline-0 px-2 py-2 text-lg flex items-center"
                  />
                  <p className={'text-sm text-red-500'}>
                    {formik.touched.title && formik.errors.title}
                  </p>
                </div>
                <div>
                  <label className={'text-sm'}>
                    Issue Type <span className={'text-red-500'}>*</span>
                  </label>
                  <div className={'relative w-full mt-1'}>
                    <button
                      type={'button'}
                      onClick={() => setTicketDeptShow(!ticketDeptShow)}
                      className={`border px-3 py-2 flex items-center justify-between ${
                        ticketDeptShow ? 'border-gray-300' : 'border-gray-300'
                      } w-full rounded text-sm`}>
                      {selectedDept ? (
                        <>
                          <span>{selectedDept.name}</span>
                          <span>
                            <i
                              className={`fi ${
                                ticketDeptShow ? 'fi-sr-caret-up' : 'fi-sr-caret-down'
                              } text-lg`}></i>
                          </span>
                        </>
                      ) : (
                        <>
                          <span>Choose Department</span>
                          <span>
                            <i
                              className={`fi ${
                                ticketDeptShow ? 'fi-sr-caret-up' : 'fi-sr-caret-down'
                              } text-lg`}></i>
                          </span>
                        </>
                      )}
                    </button>
                    {ticketDeptShow && (
                      <TicketDeptsPopover
                        hideTicketDepts={hideTicketDepts}
                        options={ticketDepartments}
                        setSelectedDept={setSelectedDept}
                        show={ticketDeptShow}
                        onClickOutside={() => setTicketDeptShow(false)}
                      />
                    )}
                  </div>
                  <p className={'text-sm text-red-500'}>
                    {formik.touched.department && formik.errors.department}
                  </p>
                </div>
                <div>
                  <label className={'text-sm'}>Website</label>
                  <select
                    // placeholder={'Describe the issue'}
                    name={'website'}
                    value={formik.values.website}
                    onChange={formik.handleChange}
                    className="w-full bg-transparent outline-0 p-2 text-sm rounded border border-gray-300 mt-1 flex items-center">
                    <option value={undefined}>--Select--</option>
                    {profile &&
                      profile.subscription_details?.subscription.map((item, index) => (
                        <option key={index} value={item.idsite}>
                          {item.website?.name} - {item.website?.main_url}
                        </option>
                      ))}
                  </select>
                  <p className={'text-sm text-red-500'}>
                    {formik.touched.website && formik.errors.website}
                  </p>
                </div>
                <div>
                  <label className={'text-sm'}>
                    Message <span className={'text-red-500'}>*</span>
                  </label>
                  <textarea
                    placeholder={'Describe the issue'}
                    name={'message'}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    rows={7}
                    className="w-full bg-transparent outline-0 p-2 text-sm rounded border border-gray-300 mt-1 flex items-center"
                  />
                  <small>Please be detailed in your message</small>
                  <p className={'text-sm text-red-500'}>
                    {formik.touched.message && formik.errors.message}
                  </p>
                </div>
                <div className={'mt-2 flex lg:justify-end'}>
                  <div className={'w-full lg:w-auto'}>
                    <PrimaryButton
                      disabled={loading}
                      title={loading ? 'Creating ticket issue...' : 'Create ticket'}
                      handler={() => formik.handleSubmit()}
                    />
                  </div>
                </div>
                {errorMessage && <Error value={errorMessage} />}
              </div>
            </form>
          )}
        </div>
      )}
    </Modal>
  );
}
