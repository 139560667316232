import { useContext, useEffect, useState } from 'react';
import { IPlan, IPlanInterval } from '../../../interfaces/components';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import PlanButton from '../../../components/global/ui/buttons/PlanButton';
import {
  AGENCY_BOOKING_URL,
  basicPlanItems,
  businessPlanItems, ORGANIZATION_ID,
  premiumPlanItems
} from '../../../constants';
import PackageItem from '../../../feature/Plans/PlanPackageItem';
import imgAllen from '../../../assets/testimonial/allan.png';
import leftQuoteIcon from '../../../assets/testimonial/left-quote.png';
import rightQuoteIcon from '../../../assets/testimonial/right-quote.png';
import NewFirstTimeCheckoutForm from '../../../feature/AccountSubscription/Checkout/NewFirstTimeCheckoutForm';
import TopHeader from '../../../components/global/TopHeader';
import { useIntercom } from 'react-use-intercom';
import imgMordec from '../../../assets/testimonial/mordechai.png';
import { usePayment } from '../Pricing/usePayment';
import {ISubscription} from "../../../interfaces/subscription";
import {useUserSites} from "../../../hooks/useUserSites";
import {OrganizationContextShopify} from "../../../contexts/OrganizationContextShopify";

export const plan = [
  { name: 'Starter', value: 1, monthlyPrice: 89, yearlyPrice: 37 },
  { name: 'Grow', value: 2, monthlyPrice: 129, yearlyPrice: 79 },
  { name: 'Scale', value: 3, monthlyPrice: 249, yearlyPrice: 79 },
  { name: 'Scale Plus', value: 4, monthlyPrice: 449, yearlyPrice: 79 },
  { name: 'Scale Plus 1', value: 5, monthlyPrice: 750, yearlyPrice: 79 },
  { name: 'Scale Plus 2', value: 6, monthlyPrice: 1250, yearlyPrice: 79 },
  { name: 'Scale Plus 3', value: 7, monthlyPrice: 3000, yearlyPrice: 79 }
];

export const intervals = [
  { name: 'Monthly', value: 'month' },
  { name: 'Yearly', value: 'year' }
];

export default function ShopifyPlans() {
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false)
  const [selected, setSelected] = useState<IPlan | null>(null);
  const [clientSelectedPlan, setClientSelectedPlan] = useState<string | null>(null);
  const [intervalSelected, setIntervalSelected] = useState<IPlanInterval>(intervals[0]);
  const { shutdown } = useIntercom();
  const { makeShopifyPayment } = usePayment();
  const [userSubscription, setUserSubscription] = useState<ISubscription | null>(null);
  const { loadUserProfile } = useUserSites();

  const [premiumSession, setPremiumSession] = useState<number>(0);

  const { profile } = useContext(OrganizationContextShopify);

  const makePayment = async (plan: number) => {
    setPaymentLoading(true);
    if (profile && profile.profile_details?.email) {
      const response =  await makeShopifyPayment({
        token: localStorage.getItem('shopify_auth_token') || localStorage.getItem('_auth'),
        domain: localStorage.getItem('shopify_auth_domain'),
        plan: plan,
        email: profile.profile_details.email,
        account_id: profile.profile_details.account_id,
        user_id: profile.profile_details.user_id
      })
      if (response?.result === 'success') {
        localStorage.removeItem(ORGANIZATION_ID)
        localStorage.setItem(ORGANIZATION_ID, profile.profile_details.account_id.toString())
        window.location.href = response.confirmationUrl
      }
    }

  }

  useEffect(() => {
    shutdown();
    setLoading(true);
    if (localStorage && localStorage.getItem('plan')) {
      setClientSelectedPlan(localStorage.getItem('plan'));
    }
    // loadUserProfile({}).then((res) => {
    //   console.log('>>>>', res)
    //   // setUserSubscription(res.subscription_details ?? null);
    //   setLoading(false);
    // });
    setLoading(false);
  }, []);

  useEffect(() => {
    shutdown();
    setLoading(true);
    if (localStorage && localStorage.getItem('plan')) {
      setClientSelectedPlan(localStorage.getItem('plan'));
    }
    setLoading(false);
  }, []);



  useEffect(() => {
    if (!profile || !profile.profile_details) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [profile]);


  if (loading || paymentLoading)
    return (
      <div className={'h-screen flex justify-center items-center'}>
        <LoadingSpinner color={'text-[#008160]'} />
      </div>
    );

  return (
    <div className={'min-h-[100vh] bg-gradient-to-t from-emerald-100 via-white to-white'}>
      <TopHeader />
      <main>
        <section className={'p-5 lg:p-10 flex flex-col items-center'}>
          {/*{profile.subscription_details?.card.length === 0 && profile.organizations.length > 1 && (*/}
          {/*  <div className={'flex justify-end mb-2 w-full'}>*/}
          {/*    <Link*/}
          {/*      to={routes.DASHBOARD}*/}
          {/*      className={*/}
          {/*        'rounded-full px-3 py-1 bg-white hover:bg-emerald-50 border border-transparent hover:border-[#008160]-lg text-[#008160] flex gap-2 items-center'*/}
          {/*      }>*/}
          {/*      <span className={'text-sm'}>Skip to dashboard</span>*/}
          {/*      <span className={'pt-1'}>*/}
          {/*        <i className="fi fi-rr-arrow-small-right text-xl"></i>*/}
          {/*      </span>*/}
          {/*    </Link>*/}
          {/*  </div>*/}
          {/*)}*/}
          <div>
            {clientSelectedPlan &&
            (clientSelectedPlan == '1' ||
              clientSelectedPlan == '2' ||
              clientSelectedPlan == '3' ||
              clientSelectedPlan == '4') ? (
              <div className={'flex flex-col justify-center items-center'}>
                <div className={'w-full lg:w-[80%] flex flex-col items-center justify-center'}>
                  <NewFirstTimeCheckoutForm
                    plan={plan[parseInt(clientSelectedPlan) - 1]}
                    interval={intervalSelected}
                  />
                  <div className={'flex justify-center mt-10 mb-5'}>
                    <div className={'grid lg:grid-cols-2 gap-8 text-white'}>
                      <div
                        className={
                          'flex md:grid md:grid-cols-7 gap-4 bg-[#2E9A6E] rounded-md p-5 md:p-8-md'
                        }>
                        <div className={'md:col-span-2 flex justify-center items-center'}>
                          <div className={'flex flex-col items-center text-center'}>
                            <img
                              className={
                                'w-[3rem] md:w-[4rem] h-[3rem] md:h-[4rem] border-2 border-white rounded-full'
                              }
                              src={imgAllen}
                              alt={'@AllanJPorter | Heatmap.com'}
                            />
                            <p className={'font-medium mt-3 text-[10px] md:text-base'}>
                              @AllanJPorter
                            </p>
                            <p className={'font-light italic text-[10px] md:text-sm'}>
                              Owner | Porter Media
                            </p>
                          </div>
                        </div>
                        <div className={'relative  md:col-span-5'}>
                          <span className={'absolute top-0 md:-top-3 -left-5 md:-left-8'}>
                            <img className={'w-5 md:w-6 h-5 md:h-6'} alt={''} src={leftQuoteIcon} />
                          </span>
                          <span className={'absolute -bottom-3 md:-bottom-3 right-1 lg:right-5'}>
                            <img
                              className={'w-5 md:w-6 h-5 md:h-6'}
                              alt={''}
                              src={rightQuoteIcon}
                            />
                          </span>
                          <p className={'font-medium text-[10.5px] md:text-base text-center'}>
                            This is the only heatmap where I&apos;m able to make decisions on my
                            website in seconds because no other software shows revenue on-site.
                            Changed our entire business for such a low price.
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          'flex md:grid md:grid-cols-7 gap-4 bg-[#2E9A6E] rounded-md p-5 md:p-8-md'
                        }>
                        <div className={'col-span-2'}>
                          <div className={'flex flex-col items-center text-center'}>
                            <img
                              className={
                                'w-[3rem] md:w-[4rem] h-[3rem] md:h-[4rem] border-2 border-white rounded-full'
                              }
                              src={imgMordec}
                              alt={'@mordechaihoff | Heatmap.com'}
                            />
                            <p className={'font-medium mt-3 text-[10px] md:text-base'}>
                              @mordechaihoff
                            </p>
                            <p className={'font-light italic text-[10px] md:text-sm'}>
                              CEO | Red Moose
                            </p>
                          </div>
                        </div>
                        <div className={'relative col-span-5'}>
                          <span className={'absolute top-0 md:-top-3 -left-6 md:-left-8'}>
                            <img className={'w-5 md:w-6 h-5 md:h-6'} alt={''} src={leftQuoteIcon} />
                          </span>
                          <span className={'absolute -bottom-3 md:-bottom-3 right-1 lg:right-5'}>
                            <img
                              className={'w-5 md:w-6 h-5 md:h-6'}
                              alt={''}
                              src={rightQuoteIcon}
                            />
                          </span>
                          <p className={'font-medium text-[10.5px] md:text-base text-center'}>
                            We&apos;ve been looking closely at how users move through our site to
                            find out what&apos;s driving the most revenue. I&apos;ve never seen such
                            useful information before. It&apos;s like I&apos;m more in control than
                            I&apos;ve ever been. By the way, I&apos;ve tried all sorts of heatmap
                            tools, but this one beats them by a mile.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : !localStorage.getItem('plan') ? (
              <div
                style={{
                  fontFamily: `'Bricolage Grotesque', sans-serif`
                }}
                className={'flex flex-col items-center'}>
                <div className={'text-center mb-8'}>
                  {<h1 className={'font-extrabold text-[1.75rem]'}>One Price, All Features</h1>}
                  {/*<p className={'text-lg'}>Choose a plan for your website needs</p>*/}
                </div>
                <div className={'w-full md:w-3/4 lg:w-auto grid lg:grid-cols-3 gap-10'}>
                  <div className={'flex flex-col justify-between rounded-md bg-[#B0F6C2] p-8'}>
                    <div>
                      <div className={'flex flex-row lg:flex-col justify-between gap-2'}>
                        <div className={'flex gap-2 '}>
                          <h3 className={'font-extrabold text-[1.9375rem] lg:text-[1.75rem]'}>
                            {plan[0].name}
                          </h3>
                        </div>

                        <div
                          className={
                            'lg:mt-5 mb-5 lg:mb-10 flex flex-col lg:flex-row items-end gap-4 leading-none'
                          }>
                          <span className={'flex items-end'}>
                            <span className={'font-extrabold text-[3.3125rem] lg:text-[2.8125rem]'}>
                              $
                            </span>
                            <span className={'font-extrabold text-[3.3125rem] lg:text-[2.8125rem]'}>
                              {intervalSelected?.value === 'month'
                                ? plan[0].monthlyPrice
                                : plan[0].yearlyPrice * 12}
                            </span>
                          </span>
                          <span className={'text-[0.8125rem] lg:text-[0.75rem]'}>
                            <span className={'inline lg:hidden'}>USD</span>/
                            {intervalSelected?.value}
                          </span>
                        </div>
                      </div>

                      <div className={'flex lg:hidden'}>
                      <PlanButton
                          title={'Start Free Trial 7 Days'}
                          handler={() => {
                            setSelected(plan[0]);
                            localStorage &&
                            localStorage.setItem('plan_opt', plan[0].value.toString());
                            makePayment(plan[0].value);
                          }}
                        />
                      </div>
                      <div className={'lg:mt-2'}>
                        <div className={'px-0 py-3 w-full '}>
                          <span className={'font-black text-[0.875rem]'}>
                            Track up to $50,000 per month
                          </span>
                        </div>
                      </div>
                      <div className={'mt-5'}>
                        <div>
                          <ul className={'mt-2 grid gap-2'}>
                            {basicPlanItems &&
                              basicPlanItems.map((item, index) => (
                                <PackageItem color={'text-[#091226]'} key={index} value={item} />
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className={'hidden lg:flex'}>
                      <PlanButton
                        title={'Start Free Trial 7 Days'}
                        handler={() => {
                          setSelected(plan[0]);
                          localStorage &&
                          localStorage.setItem('plan_opt', plan[0].value.toString());
                          makePayment(plan[0].value);
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className={
                      'flex flex-col justify-between rounded-md relative bg-[#77DB89] p-8'
                    }>
                    <span
                      className={
                        'w-full rounded-t-md absolute top-0 left-0 h-[0.625rem] bg-[#091226]'
                      }></span>
                    <div>
                      <div className={'flex flex-row lg:flex-col justify-between gap-2'}>
                        <div className={'flex justify-between gap-2'}>
                          <h3 className={'font-extrabold text-[1.75rem]'}>{plan[1].name}</h3>
                        </div>
                        <div
                          className={
                            'lg:mt-5 mb-5 lg:mb-10 flex flex-col lg:flex-row items-end gap-4 leading-none'
                          }>
                          <span className={'flex items-end'}>
                            <span className={'font-extrabold text-[3.3125rem] lg:text-[2.8125rem]'}>
                              $
                            </span>
                            <span className={'font-extrabold text-[3.3125rem] lg:text-[2.8125rem]'}>
                              {intervalSelected?.value === 'month'
                                ? plan[1].monthlyPrice
                                : plan[1].yearlyPrice * 12}
                            </span>
                          </span>
                          <span className={'text-[0.8125rem] lg:text-[0.75rem]'}>
                            <span className={'inline lg:hidden'}>USD</span>/
                            {intervalSelected?.value}
                          </span>
                        </div>
                      </div>

                      <div className={'flex lg:hidden'}>
                        <PlanButton
                          title={'Start Free Trial 7 Days'}
                          handler={() => {
                            setSelected(plan[1]);
                            localStorage &&
                            localStorage.setItem('plan_opt', plan[1].value.toString());
                            makePayment(plan[1].value);
                          }}
                        />
                      </div>
                      <div className={'lg:mt-2'}>
                        <div className={'px-0 py-3 w-full '}>
                          <span className={'font-black text-[0.875rem]'}>
                            Track up to $250,000 per month
                          </span>
                        </div>
                      </div>
                      <div className={'mt-5'}>
                        <div>
                          <ul className={'mt-2 grid gap-2'}>
                            {businessPlanItems &&
                              businessPlanItems.map((item, index) => (
                                <PackageItem color={'text-[#091226]'} key={index} value={item} />
                              ))}
                            {intervalSelected.value === 'year' && (
                              <PackageItem
                                color={'text-[#091226]'}
                                value={'Month 1 website audit by Conversion Rate experts'}
                              />
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className={'hidden lg:flex'}>
                      <PlanButton
                        title={'Start Free Trial 7 Days'}
                        handler={() => {
                          setSelected(plan[1]);
                          localStorage &&
                          localStorage.setItem('plan_opt', plan[1].value.toString());
                          makePayment(plan[1].value);
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className={
                      'flex flex-col justify-between rounded-md bg-[#091226] text-white p-8'
                    }>
                    <div>
                      <div className={'flex flex-row lg:flex-col justify-between gap-2'}>
                        <div className={'flex gap-2'}>
                          <h3 className={'font-extrabold text-[1.9375rem] lg:text-[1.75rem]'}>
                            {plan[2].name}
                          </h3>
                        </div>

                        <div
                          className={
                            'lg:mt-5 mb-5 lg:mb-10 flex flex-col lg:flex-row items-end gap-4 leading-none'
                          }>
                          <span className={'flex items-end'}>
                            <span className={'font-extrabold text-[3.3125rem] lg:text-[2.8125rem]'}>
                              $
                            </span>
                            <span className={'font-extrabold text-[3.3125rem] lg:text-[2.8125rem]'}>
                              {intervalSelected?.value === 'month' && premiumSession === 0
                                ? plan[2].monthlyPrice
                                : intervalSelected?.value === 'month' && premiumSession === 1
                                  ? plan[3].monthlyPrice
                                  : plan[2].yearlyPrice * 12}
                            </span>
                          </span>
                          <span className={'text-[0.8125rem] lg:text-[0.75rem]'}>
                            <span className={'inline lg:hidden'}>USD</span>/
                            {intervalSelected?.value}
                          </span>
                        </div>
                      </div>

                      <div className={'mb-2'}>
                        <div className={'px-0 py-3 w-full text-black'}>
                          <select
                            className={`px-2 py-3 w-full rounded border text-sm border-gray-300`}
                            name={'platform'}
                            value={premiumSession}
                            onChange={(e) => {
                              setPremiumSession(parseInt(e.target.value));
                            }}>
                            <option value={0}>Up to $500,000 /month</option>
                            <option value={1}>Up to $1,000,000 /month</option>
                          </select>
                        </div>
                      </div>
                      <div className={'flex lg:hidden'}>
                        <PlanButton
                          title={'Start Free Trial 7 Days'}
                          handler={() => {
                            setSelected(premiumSession === 0 ? plan[2] : plan[3]);
                            localStorage &&
                            localStorage.setItem(
                              'plan_opt',
                              premiumSession === 0
                                ? plan[2].value.toString()
                                : plan[3].value.toString()
                            );
                            makePayment(premiumSession === 0 ? plan[2].value : plan[3].value);
                          }}
                        />
                      </div>
                      <div className={'mt-5'}>
                        <div>
                          <ul className={'mt-2 grid gap-2'}>
                            {premiumPlanItems &&
                              premiumPlanItems.map((item, index) => (
                                <PackageItem color={'text-white'} key={index} value={item} />
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className={'hidden lg:flex '}>
                      <PlanButton
                        title={'Start Free Trial 7 Days'}
                        handler={() => {
                          setSelected(premiumSession === 0 ? plan[2] : plan[3]);
                          localStorage &&
                          localStorage.setItem(
                            'plan_opt',
                            premiumSession === 0
                              ? plan[2].value.toString()
                              : plan[3].value.toString()
                          );
                          makePayment(premiumSession === 0 ? plan[2].value : plan[3].value);
                        }}
                      />
                    </div>
                  </div>


                </div>
              </div>
            ) : null}
          </div>

          <div className={'mt-5 '}>
            <p className={'text-[0.875rem] text-slate-600 font-medium'}>Charges are billed in USD. Recurring and usage-based charges are billed every 30 days.</p>
          </div>

        </section>

      </main>
    </div>
  );
}
