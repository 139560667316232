import PrimaryButton from '../../components/global/ui/buttons/PrimaryButton';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { useContext, useState } from 'react';
import { intervals, plans } from '../../pages/main/Pricing';
import { useSubscription } from '../../pages/main/AccountSubscription/useSubscription';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import Error from '../../components/global/ui/alerts/Error';
import Modal from '../../components/global/ui/Modal';
import {
  AGENCY_BOOKING_URL,
  agencyPlanItems,
  basicPlanItems,
  businessPlanItems,
  ORGANIZATION_ID,
  premiumPlanItems
} from '../../constants';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { plan } from '../../pages/main/Plans';
import PackageItem from '../Plans/PlanPackageItem';
import { IPlan, IPlanInterval } from '../../interfaces/components';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { ISubscription } from '../../interfaces/subscription';
import {useGetShopifyAppInfo} from "../../hooks/useGetShopifyAppInfo";

export default function UpgradeSubscription({
  currentPlan,
  upgradePlan,
  currentInterval,
  subscriptionId,
  closeModal,
  accountName,
  url,
  website
}: {
  subscriptionId: string;
  currentInterval: string;
  currentPlan: number;
  upgradePlan?: number | string;
  closeModal: () => void;
  website?: any;
  accountName?: string;
  url?: string;
}) {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    upgradePlan: Yup.string().required('Select your new plan')
  });

  const { upgradeSubscription, upgradeShopifySubscription, loading, errorMessage } = useSubscription();
  const { getShopifyAppInfo, loading: shopifyAppLoading, errorMessage: shopifyAppErrorMessage } = useGetShopifyAppInfo();
  const [message, setMessage] = useState<string | null>(null);
  const [option, setOption] = useState<number | null>(upgradePlan ? (upgradePlan as number) : null);
  const [selectedPackage, setSelectedPackage] = useState<IPlan | null>(
    upgradePlan ? plans[upgradePlan as number] : plans[1]
  );
  const [shopifyLoadingSubscription, setShopifyLoadingSubscription] = useState(false)

  const { fetchOrganizationData } = useContext(OrganizationContext);

  const formik = useFormik({
    initialValues: {
      upgradePlan: upgradePlan ? upgradePlan : undefined,
      interval: 'month'
    },
    validationSchema,
    onSubmit: async (values) => handleUpgradeSubscription(values)
  });

  const handleUpgradeSubscription = async (values: FormikValues) => {
    setMessage(null);

    const shopifyAppInfoResponse = await getShopifyAppInfo({url: website.main_url})

    if (shopifyAppInfoResponse?.setupFromShopify) {
      const response = await upgradeShopifySubscription({
        currentPlan,
        upgradePlan: values.upgradePlan,
        interval: values.interval,
        subscriptionId,
        url: website.main_url
      });
      setShopifyLoadingSubscription(true)
      if (response?.result === 'success') {
        // setMessage(response.msg);
        // refetch organization data
        fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);

        window.location.href = response.confirmationUrl
      }
    } else {
      const response = await upgradeSubscription({
        currentPlan,
        upgradePlan: values.upgradePlan,
        interval: values.interval,
        subscriptionId
      });
      if (response?.result === 'success') {
        setMessage(response.msg);
        // refetch organization data
        fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
      }
    }
  };

  return (
    <Modal
      closeModal={() => {
        upgradePlan && navigate(routes.WEBSITES);
        closeModal();
      }}
      title={''}
      width={loading || message ? 'lg:w-1/3' : 'lg:w-2/3 lg:max-w-[58rem]'}>
      {loading || shopifyLoadingSubscription ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Changing plan...Please wait</h5>
        </div>
      ) : message ? (
        <SuccessMessage message={message} />
      ) : (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            formik.handleSubmit();
          }}
          className={'p-2.5 md:pt-0'}>
          <div className={'text-center'}>
            <h1 className={'font-bold text-lg lg:text-xl'}>Manage your plan</h1>
            <p className={'text-sm lg:text-sm'}>Change your current plan</p>
          </div>
          {accountName && (
            <div className={'flex gap-2 items-center font-medium'}>
              <span>Account: </span>
              <span>{accountName}</span>
            </div>
          )}
          <div className={'grid lg:grid-cols-2 gap-10 mt-5 md:mt-8'}>
            <div className={'max-h-[60svh] overflow-auto slim-scroll pr-2 col-span-1'}>
              <div className={'grid gap-3 md:grid-cols-3 lg:grid-cols-1 lg:grid-rows-3'}>
                {/*Starter card*/}
                <div
                  onClick={
                    currentPlan === 1
                      ? undefined
                      : () => {
                          setOption(1);
                          setSelectedPackage(plans[0]);
                          formik.setFieldValue('upgradePlan', 1);
                        }
                  }
                  className={`${
                    currentPlan == 1
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : option == 1
                      ? 'border-[#091226] bg-[#B0F6C2] cursor-pointer'
                      : 'border-transparent bg-[#B0F6C2] cursor-pointer'
                  } border lg:border-4 rounded md:rounded-md p-4`}>
                  <div className={'flex flex-row justify-between gap-2'}>
                    <div className={'flex gap-2'}>
                      <h3 className={'font-extrabold text-[1.375rem] lg:text-[1.725rem]'}>
                        {plan[0].name}
                      </h3>
                    </div>

                    <div
                      className={
                        'mb-5 flex flex-col lg:flex-row gap-2 md:gap-4 lg:gap-1 leading-none'
                      }>
                      <span className={'flex items-center'}>
                        <span className={'font-extrabold text-[2rem] lg:text-[1.8125rem]'}>$</span>
                        <span className={'font-extrabold text-[2rem] lg:text-[1.8125rem]'}>
                          {formik.values.interval.toLowerCase() === 'month'
                            ? plan[0].monthlyPrice
                            : plan[0].yearlyPrice * 12}
                        </span>
                      </span>
                      <span className={'text-[0.8125rem] lg:text-[0.75rem] self-end'}>
                        <span className={'inline lg:hidden'}>USD</span>/
                        {formik.values.interval.toLowerCase()}
                      </span>
                    </div>
                  </div>

                  <div className={'lg:mt-2'}>
                    <div className={'px-0 w-full flex justify-between gap-5'}>
                      <span className={'font-black text-[0.875rem]'}>
                        Track up to $50,000 per month
                      </span>
                      {currentPlan == 1 && (
                        <span
                          className={
                            'font-bold text-[0.7rem] lg:text-[0.875rem] whitespace-nowrap self-center'
                          }>
                          Current Plan
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                {/*Grow card*/}
                <div
                  onClick={
                    currentPlan === 2
                      ? undefined
                      : () => {
                          setOption(2);
                          setSelectedPackage(plans[1]);
                          formik.setFieldValue('upgradePlan', 2);
                        }
                  }
                  className={`${
                    currentPlan == 2
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : option == 2
                      ? 'border-[#091226] bg-[#77DB89] cursor-pointer'
                      : 'border-transparent bg-[#77DB89] cursor-pointer'
                  } border lg:border-4 rounded md:rounded-md relative p-4`}>
                  <span
                    className={
                      'w-full rounded-t absolute top-0 left-0 h-[0.625rem] bg-[#091226]'
                    }></span>
                  <div className={'flex flex-row justify-between gap-2'}>
                    <div className={'flex gap-2'}>
                      <h3 className={'font-extrabold text-[1.375rem] lg:text-[1.725rem]'}>
                        {plan[1].name}
                      </h3>
                    </div>

                    <div
                      className={
                        'mb-5 flex flex-col lg:flex-row gap-2 md:gap-4 lg:gap-1 leading-none'
                      }>
                      <span className={'flex items-center'}>
                        <span className={'font-extrabold text-[2rem] lg:text-[1.8125rem]'}>$</span>
                        <span className={'font-extrabold text-[2rem] lg:text-[1.8125rem]'}>
                          {formik.values.interval.toLowerCase() === 'month'
                            ? plan[1].monthlyPrice
                            : plan[1].yearlyPrice * 12}
                        </span>
                      </span>
                      <span className={'text-[0.8125rem] lg:text-[0.75rem] self-end'}>
                        <span className={'inline lg:hidden'}>USD</span>/
                        {formik.values.interval.toLowerCase()}
                      </span>
                    </div>
                  </div>

                  <div className={'lg:mt-2'}>
                    <div className={'px-0 w-full flex justify-between gap-5'}>
                      <span className={'font-black text-[0.875rem]'}>
                        Track up to $250,000 per month
                      </span>
                      {currentPlan == 2 && (
                        <span
                          className={
                            'font-bold text-[0.7rem] lg:text-[0.875rem] whitespace-nowrap self-center'
                          }>
                          Current Plan
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                {/*Scale card*/}
                <div
                  onClick={
                    currentPlan === 3
                      ? undefined
                      : () => {
                          setOption(3);
                          setSelectedPackage(plans[2]);
                          formik.setFieldValue('upgradePlan', 3);
                        }
                  }
                  className={`${
                    currentPlan == 3
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : option == 3
                      ? 'border-[#77DB89] bg-[#051e53] cursor-pointer'
                      : 'border-transparent bg-[#051e53] cursor-pointer'
                  } border lg:border-4 rounded md:rounded-md text-white p-4`}>
                  <div className={'flex flex-row justify-between gap-2'}>
                    <div className={'flex gap-2'}>
                      <h3 className={'font-extrabold text-[1.375rem] lg:text-[1.725rem]'}>
                        {plan[2].name}
                      </h3>
                    </div>

                    <div
                      className={
                        'mb-5 flex flex-col lg:flex-row gap-2 md:gap-4 lg:gap-1 leading-none'
                      }>
                      <span className={'flex items-center'}>
                        <span className={'font-extrabold text-[2rem] lg:text-[1.8125rem]'}>$</span>
                        <span className={'font-extrabold text-[2rem] lg:text-[1.8125rem]'}>
                          {formik.values.interval.toLowerCase() === 'month'
                            ? plan[2].monthlyPrice
                            : plan[2].yearlyPrice * 12}
                        </span>
                      </span>
                      <span className={'text-[0.8125rem] lg:text-[0.75rem] self-end'}>
                        <span className={'inline lg:hidden'}>USD</span>/
                        {formik.values.interval.toLowerCase()}
                      </span>
                    </div>
                  </div>

                  <div className={'lg:mt-2'}>
                    <div className={'px-0 w-full flex justify-between gap-5'}>
                      <span className={'font-black text-[0.875rem]'}>
                        Track up to $500,000 per month
                      </span>
                      {currentPlan == 3 && (
                        <span
                          className={
                            'font-bold text-[0.7rem] lg:text-[0.875rem] whitespace-nowrap self-center'
                          }>
                          Current Plan
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                {/*Scale Plus card*/}
                <div
                  onClick={
                    currentPlan === 4
                      ? undefined
                      : () => {
                          setOption(4);
                          setSelectedPackage(plans[3]);
                          formik.setFieldValue('upgradePlan', 4);
                        }
                  }
                  className={`${
                    currentPlan == 4
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : option == 4
                      ? 'border-[#77DB89] bg-[#091226] cursor-pointer'
                      : 'border-transparent bg-[#091226] cursor-pointer'
                  } border lg:border-4 rounded md:rounded-md text-white p-4`}>
                  <div className={'flex flex-row justify-between gap-2'}>
                    <div className={'flex gap-2'}>
                      <h3 className={'font-extrabold text-[1.375rem] lg:text-[1.725rem]'}>
                        {plan[3].name}
                      </h3>
                    </div>

                    <div
                      className={
                        'mb-5 flex flex-col lg:flex-row gap-2 md:gap-4 lg:gap-1 leading-none'
                      }>
                      <span className={'flex items-center'}>
                        <span className={'font-extrabold text-[2rem] lg:text-[1.8125rem]'}>$</span>
                        <span className={'font-extrabold text-[2rem] lg:text-[1.8125rem]'}>
                          {formik.values.interval.toLowerCase() === 'month'
                            ? plan[3].monthlyPrice
                            : plan[3].yearlyPrice * 12}
                        </span>
                      </span>
                      <span className={'text-[0.8125rem] lg:text-[0.75rem] self-end'}>
                        <span className={'inline lg:hidden'}>USD</span>/
                        {formik.values.interval.toLowerCase()}
                      </span>
                    </div>
                  </div>

                  <div className={'lg:mt-2 lg:mb-1'}>
                    <div className={'px-0 w-full flex justify-between gap-5'}>
                      <span className={'font-black text-[0.875rem]'}>
                        Track up to $1,000,000 per month
                      </span>
                      {currentPlan == 4 && (
                        <span
                          className={
                            'font-bold text-[0.7rem] lg:text-[0.875rem] whitespace-nowrap self-center'
                          }>
                          Current Plan
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                {/*Scale Plus New 1 card*/}
                {/*<div*/}
                {/*  onClick={*/}
                {/*    currentPlan === 5*/}
                {/*      ? undefined*/}
                {/*      : () => {*/}
                {/*          setOption(5);*/}
                {/*          setSelectedPackage(plans[4]);*/}
                {/*          formik.setFieldValue('upgradePlan', 5);*/}
                {/*        }*/}
                {/*  }*/}
                {/*  className={`${*/}
                {/*    currentPlan == 5*/}
                {/*      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'*/}
                {/*      : option == 5*/}
                {/*      ? 'border-[#77DB89] bg-[#091226] cursor-pointer'*/}
                {/*      : 'border-transparent bg-[#091226] cursor-pointer'*/}
                {/*  } border lg:border-4 rounded md:rounded-md text-white p-4`}>*/}
                {/*  <div className={'flex flex-row justify-between gap-2'}>*/}
                {/*    <div className={'flex gap-2'}>*/}
                {/*      <h3 className={'font-extrabold text-[1.375rem] lg:text-[1.725rem]'}>*/}
                {/*        {plan[4].name}*/}
                {/*      </h3>*/}
                {/*    </div>*/}

                {/*    <div*/}
                {/*      className={*/}
                {/*        'mb-5 flex flex-col lg:flex-row gap-2 md:gap-4 lg:gap-1 leading-none'*/}
                {/*      }>*/}
                {/*      <span className={'flex items-center'}>*/}
                {/*        <span className={'font-extrabold text-[2rem] lg:text-[1.8125rem]'}>$</span>*/}
                {/*        <span className={'font-extrabold text-[2rem] lg:text-[1.8125rem]'}>*/}
                {/*          {formik.values.interval.toLowerCase() === 'month'*/}
                {/*            ? plan[4].monthlyPrice*/}
                {/*            : plan[4].yearlyPrice * 12}*/}
                {/*        </span>*/}
                {/*      </span>*/}
                {/*      <span className={'text-[0.8125rem] lg:text-[0.75rem] self-end'}>*/}
                {/*        <span className={'inline lg:hidden'}>USD</span>/*/}
                {/*        {formik.values.interval.toLowerCase()}*/}
                {/*      </span>*/}
                {/*    </div>*/}
                {/*  </div>*/}

                {/*  <div className={'lg:mt-2 lg:mb-1'}>*/}
                {/*    <div className={'px-0 w-full flex justify-between gap-5'}>*/}
                {/*      <span className={'font-black text-[0.875rem]'}>*/}
                {/*        Track up to $2,000,000 per month*/}
                {/*      </span>*/}
                {/*      {currentPlan == 5 && (*/}
                {/*        <span*/}
                {/*          className={*/}
                {/*            'font-bold text-[0.7rem] lg:text-[0.875rem] whitespace-nowrap self-center'*/}
                {/*          }>*/}
                {/*          Current Plan*/}
                {/*        </span>*/}
                {/*      )}*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*Scale Plus New 2 card*/}
                {/*<div*/}
                {/*  onClick={*/}
                {/*    currentPlan === 6*/}
                {/*      ? undefined*/}
                {/*      : () => {*/}
                {/*          setOption(6);*/}
                {/*          setSelectedPackage(plans[5]);*/}
                {/*          formik.setFieldValue('upgradePlan', 6);*/}
                {/*        }*/}
                {/*  }*/}
                {/*  className={`${*/}
                {/*    currentPlan == 6*/}
                {/*      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'*/}
                {/*      : option == 6*/}
                {/*      ? 'border-[#77DB89] bg-[#091226] cursor-pointer'*/}
                {/*      : 'border-transparent bg-[#091226] cursor-pointer'*/}
                {/*  } border lg:border-4 rounded md:rounded-md text-white p-4`}>*/}
                {/*  <div className={'flex flex-row justify-between gap-2'}>*/}
                {/*    <div className={'flex gap-2'}>*/}
                {/*      <h3 className={'font-extrabold text-[1.375rem] lg:text-[1.725rem]'}>*/}
                {/*        {plan[5].name}*/}
                {/*      </h3>*/}
                {/*    </div>*/}

                {/*    <div*/}
                {/*      className={*/}
                {/*        'mb-5 flex flex-col lg:flex-row gap-2 md:gap-4 lg:gap-1 leading-none'*/}
                {/*      }>*/}
                {/*      <span className={'flex items-center'}>*/}
                {/*        <span className={'font-extrabold text-[2rem] lg:text-[1.8125rem]'}>$</span>*/}
                {/*        <span className={'font-extrabold text-[2rem] lg:text-[1.8125rem]'}>*/}
                {/*          {formik.values.interval.toLowerCase() === 'month'*/}
                {/*            ? plan[5].monthlyPrice*/}
                {/*            : plan[5].yearlyPrice * 12}*/}
                {/*        </span>*/}
                {/*      </span>*/}
                {/*      <span className={'text-[0.8125rem] lg:text-[0.75rem] self-end'}>*/}
                {/*        <span className={'inline lg:hidden'}>USD</span>/*/}
                {/*        {formik.values.interval.toLowerCase()}*/}
                {/*      </span>*/}
                {/*    </div>*/}
                {/*  </div>*/}

                {/*  <div className={'lg:mt-2 lg:mb-1'}>*/}
                {/*    <div className={'px-0 w-full flex justify-between gap-5'}>*/}
                {/*      <span className={'font-black text-[0.875rem]'}>*/}
                {/*        Track up to $5,000,000 per month*/}
                {/*      </span>*/}
                {/*      {currentPlan == 6 && (*/}
                {/*        <span*/}
                {/*          className={*/}
                {/*            'font-bold text-[0.7rem] lg:text-[0.875rem] whitespace-nowrap self-center'*/}
                {/*          }>*/}
                {/*          Current Plan*/}
                {/*        </span>*/}
                {/*      )}*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*Scale Plus New 3 card*/}
                {/*<div*/}
                {/*  onClick={*/}
                {/*    currentPlan === 7*/}
                {/*      ? undefined*/}
                {/*      : () => {*/}
                {/*          setOption(7);*/}
                {/*          setSelectedPackage(plans[6]);*/}
                {/*          formik.setFieldValue('upgradePlan', 7);*/}
                {/*        }*/}
                {/*  }*/}
                {/*  className={`${*/}
                {/*    currentPlan == 7*/}
                {/*      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'*/}
                {/*      : option == 7*/}
                {/*      ? 'border-[#77DB89] bg-[#091226] cursor-pointer'*/}
                {/*      : 'border-transparent bg-[#091226] cursor-pointer'*/}
                {/*  } border lg:border-4 rounded md:rounded-md text-white p-4`}>*/}
                {/*  <div className={'flex flex-row justify-between gap-2'}>*/}
                {/*    <div className={'flex gap-2'}>*/}
                {/*      <h3 className={'font-extrabold text-[1.375rem] lg:text-[1.725rem]'}>*/}
                {/*        {plan[6].name}*/}
                {/*      </h3>*/}
                {/*    </div>*/}

                {/*    <div*/}
                {/*      className={*/}
                {/*        'mb-5 flex flex-col lg:flex-row gap-2 md:gap-4 lg:gap-1 leading-none'*/}
                {/*      }>*/}
                {/*      <span className={'flex items-center'}>*/}
                {/*        <span className={'font-extrabold text-[2rem] lg:text-[1.8125rem]'}>$</span>*/}
                {/*        <span className={'font-extrabold text-[2rem] lg:text-[1.8125rem]'}>*/}
                {/*          {formik.values.interval.toLowerCase() === 'month'*/}
                {/*            ? plan[6].monthlyPrice*/}
                {/*            : plan[6].yearlyPrice * 12}*/}
                {/*        </span>*/}
                {/*      </span>*/}
                {/*      <span className={'text-[0.8125rem] lg:text-[0.75rem] self-end'}>*/}
                {/*        <span className={'inline lg:hidden'}>USD</span>/*/}
                {/*        {formik.values.interval.toLowerCase()}*/}
                {/*      </span>*/}
                {/*    </div>*/}
                {/*  </div>*/}

                {/*  <div className={'lg:mt-2 lg:mb-1'}>*/}
                {/*    <div className={'px-0 w-full flex justify-between gap-5'}>*/}
                {/*      <span className={'font-black text-[0.875rem]'}>*/}
                {/*        Track up to $10,000,000 per month*/}
                {/*      </span>*/}
                {/*      {currentPlan == 7 && (*/}
                {/*        <span*/}
                {/*          className={*/}
                {/*            'font-bold text-[0.7rem] lg:text-[0.875rem] whitespace-nowrap self-center'*/}
                {/*          }>*/}
                {/*          Current Plan*/}
                {/*        </span>*/}
                {/*      )}*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>
            <div className={'col-span-1'}>
              <div className={'bg-[#E2FDEC] px-5 pt-5 pb-14 rounded mb-3 relative'}>
                {option == 1 ? (
                  <>
                    <h5 className={'font-semibold text-base text-black'}>
                      Starter Package includes:
                    </h5>
                    <ul className={'mt-2 grid gap-2 text-sm'}>
                      <li className={'flex gap-2 items-start'}>
                        <span>
                          <i className={`fi fi-rr-check text-[#091226]`}></i>
                        </span>
                        <span
                          style={{ fontFamily: "'CircularStd-Medium', sans-serif" }}
                          className={'text-[0.875rem] font-bold'}>
                          Track up to $50,000 per month
                        </span>
                      </li>
                      {basicPlanItems &&
                        basicPlanItems.map((item, index) => (
                          <PackageItem color={'text-[#091226]'} key={index} value={item} />
                        ))}
                    </ul>
                  </>
                ) : option == 2 ? (
                  <>
                    <h5 className={'font-semibold text-base text-black'}>Grow Package includes:</h5>
                    <ul className={'mt-2 grid gap-2 list-disc text-sm'}>
                      <li className={'flex gap-2 items-start'}>
                        <span>
                          <i className={`fi fi-rr-check text-[#091226]`}></i>
                        </span>
                        <span
                          style={{ fontFamily: "'CircularStd-Medium', sans-serif" }}
                          className={'text-[0.875rem] font-black'}>
                          Track up to $250,000 per month
                        </span>
                      </li>
                      {businessPlanItems &&
                        businessPlanItems.map((item, index) => (
                          <PackageItem color={'text-[#091226]'} key={index} value={item} />
                        ))}
                    </ul>
                  </>
                ) : option == 3 ? (
                  <>
                    <h5 className={'font-semibold text-base text-black'}>
                      Scale Package includes:
                    </h5>
                    <ul className={'mt-2 grid gap-2 list-disc text-sm'}>
                      <li className={'flex gap-2 items-start'}>
                        <span>
                          <i className={`fi fi-rr-check text-[#091226]`}></i>
                        </span>
                        <span
                          style={{ fontFamily: "'CircularStd-Medium', sans-serif" }}
                          className={'text-[0.875rem] font-bold'}>
                          Track up to $500,000 per month
                        </span>
                      </li>
                      {premiumPlanItems &&
                        premiumPlanItems.map((item, index) => (
                          <PackageItem color={'text-[#091226]'} key={index} value={item} />
                        ))}
                    </ul>
                  </>
                ) : option == 4 || option == 5 || option == 6 || option == 7 ? (
                  <>
                    <h5 className={'font-semibold text-base text-black'}>
                      Scale Plus Package includes:
                    </h5>
                    <ul className={'mt-2 grid gap-2 text-sm'}>
                      <li className={'flex gap-2 items-start'}>
                        <span>
                          <i className={`fi fi-rr-check text-[#091226]`}></i>
                        </span>
                        <span
                          style={{ fontFamily: "'CircularStd-Medium', sans-serif" }}
                          className={'text-[0.875rem] font-bold'}>
                          Track up to $1,000,000 per month
                        </span>
                      </li>
                      {premiumPlanItems &&
                        premiumPlanItems.map((item, index) => (
                          <PackageItem color={'text-[#091226]'} key={index} value={item} />
                        ))}
                    </ul>
                  </>
                ) : (
                  <>
                    <h5 className={'text-lg flex gap-2'}>
                      <span>Current Plan:</span>
                      <span className={'font-bold'}>
                        {currentPlan === 1
                          ? 'Starter'
                          : currentPlan === 2
                          ? 'Grow'
                          : currentPlan === 3
                          ? 'Scale'
                          : currentPlan === 4
                          ? 'Scale Plus'
                          : null}
                      </span>
                    </h5>
                    {formik.values.upgradePlan === undefined && !option && (
                      <ul className={'mt-2 grid gap-2 text-sm'}>
                        <li className={'flex gap-2 items-start'}>
                          <span>
                            <i className={`fi fi-rr-check text-[#091226]`}></i>
                          </span>
                          <span
                            style={{ fontFamily: "'CircularStd-Medium', sans-serif" }}
                            className={'text-[0.875rem] font-bold'}>
                            {currentPlan === 1
                              ? 'Track up to $50,000 per month'
                              : currentPlan === 2
                              ? 'Track up to $250,000 per month'
                              : currentPlan === 3
                              ? 'Track up to $500,000 per month'
                              : currentPlan === 4
                              ? 'Track up to $1,000,000 per month'
                              : null}
                          </span>
                        </li>
                        {currentPlan === 1
                          ? basicPlanItems.map((item, index) => (
                              <PackageItem color={'text-[#091226]'} key={index} value={item} />
                            ))
                          : currentPlan === 2
                          ? businessPlanItems.map((item, index) => (
                              <PackageItem color={'text-[#091226]'} key={index} value={item} />
                            ))
                          : currentPlan === 3
                          ? premiumPlanItems.map((item, index) => (
                              <PackageItem color={'text-[#091226]'} key={index} value={item} />
                            ))
                          : currentPlan === 4 ||
                            currentPlan === 5 ||
                            currentPlan === 6 ||
                            currentPlan === 7
                          ? premiumPlanItems.map((item, index) => (
                              <PackageItem color={'text-[#091226]'} key={index} value={item} />
                            ))
                          : null}
                      </ul>
                    )}
                  </>
                )}
              </div>

              <div className={'flex justify-center items-center'}>
                <div className={'w-[40%]'}>
                  <PrimaryButton
                    disabled={loading || shopifyAppLoading || formik.values.upgradePlan === undefined}
                    title={loading || shopifyAppLoading ? 'Upgrading plan...' : 'Upgrade plan'}
                    handler={() => formik.handleSubmit()}
                  />
                </div>
              </div>
              {errorMessage && <Error value={errorMessage} />}
            </div>
          </div>
        </form>
      )}
    </Modal>
  );
}
