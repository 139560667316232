import { Link, useNavigate } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { useSignup } from './useSignup';
import RightSectionPlaceholder from '../../../layouts/authentication/RightSectionPlaceholder';
import InputWithoutLabel from '../../../components/input/InputWithoutLabel';
import PrimaryButton from '../../../components/global/ui/buttons/PrimaryButton';
import InputPassword from '../../../components/input/InputPassword';
import AuthFormHeader from '../../../components/form/AuthFormHeader';
import TopHeader from '../../../components/global/TopHeader';
import Error from '../../../components/global/ui/alerts/Error';
import { useContext, useEffect, useState } from 'react';
import Success from '../../../components/global/ui/alerts/Success';
import { useQuery } from '../../../util/global';
import { useSignIn, useSignOut } from 'react-auth-kit';
import { AMPLITUDE_EVENTS, CLIENT_URL, ORGANIZATION_ID } from '../../../constants';
import { useTeamInvites } from '../../main/TeamManagement/useTeamInvites';
import { routes } from '../../../constants/routes';
import { useUserSites } from '../../../hooks/useUserSites';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import imgAshvin from '../../../assets/testimonial/ash.png';
import imgBrandtify from '../../../assets/testimonial/brandtify-min.png';
import imgOliver from '../../../assets/testimonial/oliver-min.png';
import leftQuoteIcon from '../../../assets/testimonial/left-quote.png';
import rightQuoteIcon from '../../../assets/testimonial/right-quote.png';
import { useIntercom } from 'react-use-intercom';
import { trackAmplitudeEvent, updateAmplitudeUserId } from '../../../util/amplitudeService';

export default function Register() {
  const { signUp, addToCartEvent, errorMessage } = useSignup();
  const {
    validateInvitation,
    loading: validateInvitationLoading,
    errorMessage: validateInvitationError
  } = useTeamInvites();

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const query = useQuery();
  const signOut = useSignOut();
  const signIn = useSignIn();
  const { shutdown } = useIntercom();

  const { loadUserProfile } = useUserSites();
  const { setSelectedOrganization, setProfile } = useContext(OrganizationContext);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Enter your full name'),
    email: Yup.string().email('Enter a valid email address').required('Email address is required'),
    password: Yup.string()
      .min(8, 'Password character should be longer than 8')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\-\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
      .required('Password is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      username: '',
      email: query.get('email') ? (query.get('email') as string) : '',
      password: ''
    },
    validationSchema,
    onSubmit: async (values) => handleSignup(values)
  });

  useEffect(() => {
    trackAmplitudeEvent(AMPLITUDE_EVENTS.VISIT_ACCOUNT_CREATION_PAGE);
    shutdown();
    if (
      query &&
      (query.get('plan') == '1' ||
        query.get('plan') == '2' ||
        query.get('plan') == '3' ||
        query.get('plan') == '4')
    ) {
      signOut();
      localStorage && localStorage.setItem('plan', query.get('plan') as string);
    }
    document.title = 'Heatmap Register';
    if (query && query.get('invite')) {
      localStorage.removeItem('email');
      localStorage.removeItem('password');
      localStorage.removeItem(ORGANIZATION_ID);
      localStorage.removeItem(CLIENT_URL);
      signOut();
      if (query.get('invite') && query.get('email')) {
        validateInvitation({
          email: query.get('email') as string,
          invite_token: query.get('invite') as string
        }).then((response) => {
          if (response.result == 'success' && response.additional.shouldRegister == 1) {
            setSuccessMessage(response.msg || response.message);
            setTimeout(() => {
              navigate(
                `${routes.REGISTER}?invite=${query.get('invite')}&email=${query.get('email')}`
              );
            }, 2000);
          } else if (response.result == 'success' && response.additional.shouldRegister == 0) {
            setSuccessMessage(response.msg || response.message);
            navigate(routes.LOGIN);
          }
        });
      }
    }
  }, []);

  const handleSignup = async (values: FormikValues) => {
    setLoading(true);
    const response = await signUp({
      name: values.name,
      username: values.username,
      email: values.email,
      password: values.password,
      invite: query.get('invite') && query.get('invite')
    });

    if (response.result == 'error') {
      setLoading(false);
    }

    if (query.get('invite') && response.result == 'success' && response.token) {
      // call amplitude account creation successful event
      trackAmplitudeEvent(AMPLITUDE_EVENTS.COMPLETED_ACCOUNT_CREATION);

      // call amplitude setUserId after successful registration
      updateAmplitudeUserId(response.token.user_id);
      setSuccessMessage(response.msg || response.message);

      loadUserProfile({
        auth_token: response.token.token,
        email: response.token.email
      }).then((res) => {
        if (res) {
          setProfile(res);
          // setSelectedOrganization(res.organizations[0]);
          console.log('5555555555555555555')
          localStorage.setItem(ORGANIZATION_ID, response.token.account_id);
          if (res.profile_details.super_user == 1) {
            signIn({
              token: response.token.token,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser: false,
                hash: response.token.hash
              },
              tokenType: 'Bearer'
            });
          } else if (res.subscription_details.subscription.length === 0) {
            navigate(routes.PLANS);
            signIn({
              token: response.token.token,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser: res.subscription_details.subscription.length === 0,
                hash: response.token.hash
              },
              tokenType: 'Bearer'
            });
            setLoading(false);
          } else {
            signIn({
              token: response.token.token,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser: false,
                hash: response.token.hash
              },
              tokenType: 'Bearer'
            });
            setLoading(false);
            navigate(routes.DASHBOARD);
          }
        }
      });
    } else if (
      query &&
      response.result == 'success' &&
      (query.get('plan') == '0' ||
        query.get('plan') == '1' ||
        query.get('plan') == '2' ||
        query.get('plan') == '3' ||
        query.get('plan') == '4')
    ) {
      trackAmplitudeEvent(AMPLITUDE_EVENTS.COMPLETED_ACCOUNT_CREATION);
      // call amplitude setUserId after successful registration
      updateAmplitudeUserId(response.token.user_id);
      addToCartEvent({ plan: query.get('plan') as string, token: response.token.token }).then(
        (addToCartRes) => {
          setSuccessMessage(response.msg || response.message);
          loadUserProfile({
            auth_token: response.token.token,
            email: response.token.email
          }).then((res) => {
            if (res) {
              setProfile(res);
              // setSelectedOrganization(res.organizations[0]);
              console.log('6666666666666666666666666666666666')
              localStorage.setItem(ORGANIZATION_ID, res.organizations[0].account_id);
              if (res.profile_details.super_user == 1) {
                signIn({
                  token: response.token.token,
                  expiresIn: 720,
                  authState: {
                    ...res.profile_details,
                    newUser: false,
                    hash: response.token.hash
                  },
                  tokenType: 'Bearer'
                });
              } else if (res.subscription_details.subscription.length === 0) {
                navigate(routes.PLANS);
                signIn({
                  token: response.token.token,
                  expiresIn: 720,
                  authState: {
                    ...res.profile_details,
                    newUser: res.subscription_details.subscription.length === 0,
                    hash: response.token.hash
                  },
                  tokenType: 'Bearer'
                });
                setLoading(false);
              } else {
                signIn({
                  token: response.token.token,
                  expiresIn: 720,
                  authState: {
                    ...res.profile_details,
                    newUser: false,
                    hash: response.token.hash
                  },
                  tokenType: 'Bearer'
                });
                setLoading(false);
                navigate(routes.DASHBOARD);
              }
            }
          });
        }
      );
    } else if (response.result == 'success') {
      // setSuccessMessage(
      //   'We have sent you an 6-digit pin to your email. Use it to verify your account'
      // );
      trackAmplitudeEvent(AMPLITUDE_EVENTS.COMPLETED_ACCOUNT_CREATION);

      // call amplitude setUserId after successful registration
      updateAmplitudeUserId(response.token.user_id);
      setSuccessMessage('Your account has been created successfully!');
      loadUserProfile({
        auth_token: response?.token.token,
        email: response.token.email
      }).then((res) => {
        if (res) {
          setProfile(res);
          // setSelectedOrganization(res.organizations[0]);
          console.log('7777777777777777777')
          localStorage.setItem(ORGANIZATION_ID, res.organizations[0].account_id);
          if (res.profile_details.super_user == 1) {
            signIn({
              token: response.token.token,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser: false,
                hash: response.token.hash
              },
              tokenType: 'Bearer'
            });
          } else if (res.subscription_details.subscription.length === 0) {
            navigate(routes.PLANS);
            signIn({
              token: response.token.token,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser: res.subscription_details.subscription.length === 0,
                hash: response.token.hash
              },
              tokenType: 'Bearer'
            });
            setLoading(false);
          } else {
            signIn({
              token: response.token.token,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser: false,
                hash: response.token.hash
              },
              tokenType: 'Bearer'
            });
            setLoading(false);
            navigate(routes.DASHBOARD);
          }
        }
      });

      // setTimeout(() => {
      //   // navigate(routes.VERIFY_EMAIL)
      //   navigate(routes.PLANS);
      // }, 2000);
    }
  };

  return (
    <main className="main-container">
      <TopHeader />

      <div className="grid lg:grid-cols-2 sides-container">
        <section className="pt-[10vh] pb-16 lg:pb-0 px-5 flex flex-col items-center">
          <form
            id={'account_register_form'}
            onSubmit={(event) => {
              event.preventDefault();
              formik.handleSubmit();
            }}
            className="w-full md:w-1/2 flex flex-col">
            <AuthFormHeader
              title={'Let’s get you started!'}
              description={'Create a new account.'}
            />

            <InputWithoutLabel
              name={'name'}
              value={formik.values.name}
              changeHandler={formik.handleChange}
              placeholder={'Full name'}
              autoFocus={true}
            />
            <p className={'text-sm text-red-500'}>{formik.touched.name && formik.errors.name}</p>

            <InputWithoutLabel
              name={'email'}
              type={'email'}
              value={formik.values.email}
              changeHandler={formik.handleChange}
              placeholder={'Email'}
            />
            <p className={'text-sm text-red-500'}>{formik.touched.email && formik.errors.email}</p>
            <InputPassword
              name={'password'}
              value={formik.values.password}
              changeHandler={formik.handleChange}
              placeholder={'Password'}
            />
            <p className={'text-sm text-red-500'}>
              {formik.touched.password && formik.errors.password}
            </p>

            <span className="text-sm text-left text-gray-500 mt-1 mb-3">
              8 character minimum, lower and uppercase
            </span>
            <div className={'mt-5'}>
              <PrimaryButton
                disabled={loading}
                title={loading ? 'Creating an account...' : 'Create account'}
                handler={() => formik.handleSubmit()}
              />
              {errorMessage && <Error value={errorMessage} />}
              {successMessage && <Success value={successMessage} />}

              <p className="text-sm text-center text-black mt-2">
                Already have an account?{' '}
                <Link to={routes.LOGIN} className="text-secondary">
                  Sign in
                </Link>
              </p>
            </div>
          </form>
        </section>
        <section className={' bg-[#008160] px-5 py-10 md:p-10 text-white'}>
          <div>
            <h2 className={'font-semibold text-lg lg:text-2xl text-center lg:text-right'}>
              What our clients say about us...
            </h2>
          </div>
          <div className={'mt-12 flex flex-col gap-10'}>
            <div
              className={
                'flex md:grid md:grid-cols-7 gap-4 bg-[#2E9A6E] p-5 md:p-8 drop-shadow-md'
              }>
              <div className={'col-span-2'}>
                <div className={'flex flex-col items-center md:-mt-16 text-center'}>
                  <img
                    className={
                      'w-[3rem] md:w-[5.5rem] h-[3rem] md:h-[5.5rem] border-2 border-white rounded-full'
                    }
                    src={imgAshvin}
                    alt={'@ashvinmelwani | Heatmap.com'}
                  />
                  <p className={'font-medium mt-3 text-[10px] md:text-base'}>@ashvinmelwani</p>
                  <p className={'font-light italic text-[10px] md:text-sm'}>CMO | Obvi</p>
                </div>
              </div>
              <div className={'relative col-span-5'}>
                <span className={'absolute -top-8 md:-top-6 -left-8'}>
                  <img className={'w-6 md:w-8 h-6 md:h-8'} alt={''} src={leftQuoteIcon} />
                </span>
                <span className={'absolute -bottom-8 md:-bottom-5 right-5'}>
                  <img className={'w-6 md:w-8 h-6 md:h-8'} alt={''} src={rightQuoteIcon} />
                </span>
                <p className={'font-medium text-[11px] md:text-base text-center'}>
                  I&apos;ve been able to figure out what elements actually increase AOV and optimize
                  our landing pages to drive more first purchase profitability, we&apos;re up 23%
                  YoY
                </p>
              </div>
            </div>
            <div
              className={
                'flex md:grid md:grid-cols-7 gap-4 bg-[#2E9A6E] p-5 md:p-8 drop-shadow-md'
              }>
              <div className={'relative col-span-5'}>
                <span className={'absolute -top-8 md:-top-6 -left-4 md:-left-5'}>
                  <img className={'w-6 md:w-8 h-6 md:h-8'} alt={''} src={leftQuoteIcon} />
                </span>
                <span className={'absolute -bottom-8 md:-bottom-5 right-5'}>
                  <img className={'w-6 md:w-8 h-6 md:h-8'} alt={''} src={rightQuoteIcon} />
                </span>
                <p className={'font-medium text-[11px] md:text-base text-center'}>
                  Since we switched to Heatmap.com our split tests have literally DOUBLED in
                  revenue. It&apos;s crazy not to get Heatmap.com on your website.
                </p>
              </div>
              <div className={'col-span-2 flex justify-center items-center'}>
                <div className={'flex flex-col items-center md:-mt-16 text-center'}>
                  <img
                    className={
                      'w-[3rem] md:w-[5.5rem] h-[3rem] md:h-[5.5rem] border-2 border-white rounded-full'
                    }
                    src={imgOliver}
                    alt={'@oliverkenyon | Heatmap.com'}
                  />
                  <p className={'font-medium mt-3 text-[10px] md:text-base'}>@oliverkenyon</p>
                  <p className={'font-light italic text-[10px] md:text-sm'}>ConversionWise</p>
                </div>
              </div>
            </div>
            <div
              className={
                'flex md:grid md:grid-cols-7 gap-4 bg-[#2E9A6E] p-5 md:p-8 drop-shadow-md'
              }>
              <div className={'md:col-span-2 flex justify-center items-center'}>
                <div className={'flex flex-col items-center md:-mt-16 text-center'}>
                  <img
                    className={
                      'w-[3rem] md:w-[5.5rem] h-[3rem] md:h-[5.5rem] border-2 border-white rounded-full'
                    }
                    src={imgBrandtify}
                    alt={'@brandtify | Heatmap.com'}
                  />
                  <p className={'font-medium mt-3 text-[10px] md:text-base'}>@brandtify</p>
                  <p className={'font-light italic text-[10px] md:text-sm'}>Oddit</p>
                </div>
              </div>
              <div className={'relative  md:col-span-5'}>
                <span className={'absolute -top-8 md:-top-6 -left-8'}>
                  <img className={'w-6 md:w-8 h-6 md:h-8'} alt={''} src={leftQuoteIcon} />
                </span>
                <span className={'absolute -bottom-8 md:-bottom-5 right-5'}>
                  <img className={'w-6 md:w-8 h-6 md:h-8'} alt={''} src={rightQuoteIcon} />
                </span>
                <p className={'font-medium text-[11px] md:text-base text-center'}>
                  Heatmap.com is extremely valuable for anyone working in UX design because it shows
                  what elements on the website actually drive revenue. Great cheat code for
                  beautiful and effective design.
                </p>
              </div>
            </div>
          </div>
          <div className={'mt-10'}>
            <h2 className={'font-semibold text-base lg:text-2xl text-center lg:text-left'}>
              Join thousands of businesses using revenue-based website data to transform their
              business and increase revenue quickly.
            </h2>
          </div>
        </section>
        {/*<RightSectionPlaceholder />*/}
      </div>
    </main>
  );
}
