import { ChangeEvent, FocusEvent, KeyboardEvent, useContext, useMemo, useState } from 'react';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import Error from '../../components/global/ui/alerts/Error';
import Modal from '../../components/global/ui/Modal';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { ORGANIZATION_ID, RE_DIGIT } from '../../constants';
import { useSetupTwoFA } from '../../pages/main/AccountProfile/useSetupTwoFA';
import LoadingSpinnerMini from '../../components/global/LoadingSpinnerMini';
import { IUserAuthData } from '../../interfaces/user';
import twofaIcon from '../../assets/icons/twofa.png';
import { routes } from '../../constants/routes';
import { useUserSites } from '../../hooks/useUserSites';
import { useNavigate } from 'react-router-dom';
import { useSignIn } from 'react-auth-kit';
import Success from '../../components/global/ui/alerts/Success';

export default function LoginTwoFactorAuthentication({
  user,
  closeModal
}: {
  user: IUserAuthData;
  closeModal: () => void;
}) {
  const { verify2FA, verifyLoading, errorMessage } = useSetupTwoFA();
  const { setProfile, setSelectedOrganization } = useContext(OrganizationContext);

  const [message, setMessage] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);
  const { loadUserProfile } = useUserSites();
  const navigate = useNavigate();

  const signIn = useSignIn();

  const [otp, setOtp] = useState('');

  const otpDigits = useMemo(() => {
    const valueArray = otp.split('');
    const items: Array<string> = [];

    for (let i = 0; i < 6; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [otp, otp.length]);

  const inputOnChange = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
    const target = e.target;
    let targetValue = target.value;
    const isTargetValueDigit = RE_DIGIT.test(targetValue);
    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const newValue = otp.substring(0, idx) + targetValue + otp.substring(idx + 1);
    setOtp(newValue);

    if (!isTargetValueDigit) {
      return;
    }

    const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  const inputOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (e.key !== 'Backspace' || target.value !== '') {
      return;
    }

    const previousElementSibling = target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnFocus = (e: FocusEvent<HTMLInputElement>) => {
    const { target } = e;

    target.setSelectionRange(0, target.value.length);
  };

  const handleVerify2FA = async (values: string) => {
    try {
      const response = await verify2FA({
        code: values,
        secret: user.twofactor_secret,
        userToken: user.email as string
      });

      if (response?.token && response.hash) {
        setMessage('Verified successfully!');

        setTimeout(() => {
          // load user profile to log into dashboard
          loadUserProfile({ auth_token: response.token, email: response.email }).then((res) => {
            if (res) {
              setProfile(res);
              // setSelectedOrganization(res.organizations[0]);
              console.log('444444444444444444444')
              localStorage.setItem(ORGANIZATION_ID, res.organizations[0].account_id);
              if (res.profile_details.super_user == 1) {
                signIn({
                  token: response.token,
                  expiresIn: 720,
                  authState: {
                    ...res.profile_details,
                    newUser: false,
                    hash: response.hash
                  },
                  tokenType: 'Bearer'
                });
                setLoading(false);
              } else if (
                res.profile_details.account_holder == 1 &&
                res.subscription_details.subscription.length === 0
              ) {
                signIn({
                  token: response.data.token,
                  expiresIn: 720,
                  authState: {
                    ...res.profile_details,
                    newUser:
                      res.subscription_details.card.length === 0 && res.organizations.length === 1,
                    hash: response.hash
                  },
                  tokenType: 'Bearer'
                });
                navigate(routes.PLANS);
                setLoading(false);
              } else {
                signIn({
                  token: response.token,
                  expiresIn: 720,
                  authState: {
                    ...res.profile_details,
                    newUser: false,
                    hash: response.hash
                  },
                  tokenType: 'Bearer'
                });
                setLoading(false);
                navigate(routes.DASHBOARD);
              }
            }
          });

          // setMessage(response.msg);
        }, 3000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal closeModal={closeModal} title={''} width={'w-full md:w-3/5 lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Updating your profile...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handleVerify2FA(otp);
              }}
              className={'lg:p-5'}>
              <div className={'flex justify-center items-center'}>
                <span className={'rounded-full p-4 bg-emerald-50 border border-emerald-200'}>
                  <img src={twofaIcon} className={'w-8 md:w-10 h-8 md:h-10'} alt={''} />
                </span>
              </div>
              <div className={'mt-3 mb-5 text-center'}>
                <p className={'text-base font-semibold'}>Verification Code</p>
                <p className={'text-xs'}>
                  Enter the 6-pin verification code from your Authenticator app after you scanned
                  the QR code
                </p>
              </div>
              <div className={'grid grid-cols-6 gap-2 lg:gap-2 my-5'}>
                {otpDigits &&
                  otpDigits.map((digit, index) => (
                    <input
                      key={index}
                      className="bg-gray-100 w-full h-[5vh] lg:h-[8vh] rounded border border-[#E6E7E8] text-xl text-center"
                      type="text"
                      inputMode={'numeric'}
                      autoComplete={'one-time-code'}
                      pattern="\d{1}"
                      maxLength={6}
                      value={digit}
                      onKeyDown={inputOnKeyDown}
                      onFocus={inputOnFocus}
                      onChange={(e) => inputOnChange(e, index)}
                      required={true}
                    />
                  ))}
              </div>
              <div className={'flex justify-center items-center mt-8'}>
                <button
                  type={'submit'}
                  disabled={verifyLoading}
                  onClick={() => handleVerify2FA(otp)}
                  className={`w-full lg:w-3/5 rounded ${
                    verifyLoading
                      ? 'bg-emerald-200 hover:bg-emerald-200'
                      : 'bg-[#008160] hover:bg-emerald-600'
                  } text-white font-medium px-3 md:px-4 py-3 md:py-3 text-xs md:text-sm flex justify-center`}>
                  {verifyLoading ? <LoadingSpinnerMini color={'text-white'} /> : 'Verify account'}
                </button>
              </div>
              {errorMessage && <Error value={errorMessage as string} />}
              {message && <Success value={message as string} />}
            </form>
          )}
        </div>
      )}
    </Modal>
  );
}
