import { useState } from 'react';
import { getRequest } from '../../../requests';
import { useAuthHeader } from 'react-auth-kit';

export const useDeleteWebsite = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const deleteWebsite = async ({
    name,
    url,
    siteId
  }: {
    name: string;
    url: string;
    siteId: number;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.deleteWebsite',
          name,
          url,
          siteId,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error deleting website');
      setLoading(false);
    }
  };

  const deleteShopifyWebsite = async ({
                                 name,
                                 url,
                                 siteId
                               }: {
    name: string;
    url: string;
    siteId: number;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'ShopifyApp.deleteWebsite',
          name,
          url,
          siteId,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error deleting website');
      setLoading(false);
    }
  };

  return { deleteWebsite, deleteShopifyWebsite, loading, errorMessage };
};
